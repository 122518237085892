// eslint-disable-next-line no-restricted-imports
import {
  UpdateClientByAdminDto_Input,
  MutationInput_ClientController_UpdateById_Input_PepConnectionType as PepConnectionTypeApi,
  MutationInput_ClientController_UpdateById_Input_RiskLevel,
  MutationInput_ClientController_UpdateById_Input_InformationUpdateFrequency,
  MutationInput_ClientController_UpdateById_Input_Tariff,
} from 'api/generated/graphql';
import { RelationType } from 'api/types/entity';
import { UpdateClientParams } from 'api/types/params';
import { formatToISODate } from 'helpers';

import {
  parseIdentityDocumentParam,
  parseManagementTypeParam,
  parseServiceContractParam,
} from '../parseParam';

import {
  parseBiographyParam,
  parseCategoryTypeParam,
  parseCurrencyParam,
  parseDebtsParam,
  parseEconomicSectorTypeParam,
  parseFinancingSourcesParam,
  parseInformationUpdateFrequency,
  parseOpenAccountOrderParam,
  parseOrderSubmissionContactsParam,
  parseRiskLevel,
} from './parseParam';

const relationType: Record<RelationType, PepConnectionTypeApi> = {
  [RelationType.not]: PepConnectionTypeApi.None,
  [RelationType.yesSpouses]: PepConnectionTypeApi.FamilyMember,
  [RelationType.yesIAm]: PepConnectionTypeApi.Self,
};

export const parseUpdateClientParams = (
  params: UpdateClientParams
): UpdateClientByAdminDto_Input => ({
  versionNumber: params.versionNumber,
  firstName: params.firstName,
  lastName: params.lastName,
  middleName: params.middleName,
  dateOfBirth: formatToISODate(params.dateOfBirth),
  citizenships: params.citizenships,
  biography: parseBiographyParam(params.biography),
  debts: parseDebtsParam(params.debts),
  bankAccounts: params.bankAccounts,
  financingSources: parseFinancingSourcesParam(params.financingSources),
  placeOfBirth: params.placeOfBirth,
  registrationAddress: params.registrationAddress,
  residentialAddress: params.residentialAddress,
  taxResidences: params.taxResidences,
  orderSubmissionContacts: parseOrderSubmissionContactsParam(
    params.orderSubmissionContacts
  ),
  connectedPepInfo: params.connectedPepInfo,
  reason: 'TODO reason', // TODO: add reason

  pepConnectionType: relationType[params.pepConnection],

  withdrawalAccounts: params.withdrawalAccounts.map((p) => ({
    ...p,
    currency: parseCurrencyParam(p.currency),
  })),

  accreditedInvestorStatus: params.accreditedInvestorStatus,

  riskLevel:
    parseRiskLevel<MutationInput_ClientController_UpdateById_Input_RiskLevel>(
      params.riskLevel
    ),
  informationUpdateFrequency:
    parseInformationUpdateFrequency<MutationInput_ClientController_UpdateById_Input_InformationUpdateFrequency>(
      params.informationUpdateFrequency
    ),
  riskLevelReviewDate: params.riskLevelReviewDate
    ? formatToISODate(params.riskLevelReviewDate)
    : null,
  lastInformationUpdateDate: params.lastInformationUpdateDate
    ? formatToISODate(params.lastInformationUpdateDate)
    : null,
  lastOperationMonitoringResults: params.lastOperationMonitoringResults ?? null,
  usedServices: params.usedServices ?? null,

  code: params.code,
  centralDepositoryAccountNumber: params.centralDepositoryAccountNumber,
  centralDepositoryAccountNumberOpeningDate:
    params.centralDepositoryAccountNumberOpeningDate
      ? formatToISODate(params.centralDepositoryAccountNumberOpeningDate)
      : null,
  managerId: params.managerId,

  additionalInformationFileIds: params.additionalInformationFileIds,
  additionalDocumentFileIds: params.additionalDocumentFileIds,

  openAccountOrder: parseOpenAccountOrderParam(params.openAccountOrder),

  category: parseCategoryTypeParam(params.category),
  economicSector: parseEconomicSectorTypeParam(params.economicSector),
  managementType: parseManagementTypeParam(params.managementType),
  isFinancialMonitoringSubject: params.isFinancialMonitoringSubject,
  isKazakhstanResident: params.isKazakhstanResident,
  taxBenefits: params.taxBenefits,

  serviceContract: parseServiceContractParam(params.serviceContract),

  identityDocument: parseIdentityDocumentParam(params.identityDocument),

  tariff: params.tariff
    ? MutationInput_ClientController_UpdateById_Input_Tariff[params.tariff]
    : undefined,
});
