// eslint-disable-next-line no-restricted-imports
import { LegalClientBranchFragment } from 'api/generated/graphql';
import { LegalClientBranchEntity } from 'api/types/entity';
import { lodash } from 'helpers';

import { parseFileDocumentEntity } from '../client/parseEntity/parseFileEntity';
import { parseLegalClientTaxResidency } from '../legal/parseEntity/parseLegalClientTaxResidency';
import { parseOboardingData } from '../parseOboardingData';

import {
  parseLegalClientBranchContacts,
  parseLegalClientBranchLicenseInfo,
  parseLegalClientBranchStateRegistration,
} from './parseEntity';

export const parseLegalClientBranchEntity = (
  data: LegalClientBranchFragment
): LegalClientBranchEntity => ({
  id: data.id,
  versionNumber: data.version?.number,
  createdAt: new Date(data.createdAt),
  fullName: data.fullName,
  legalClientId: data.legalClientId || undefined,
  onboarding: parseOboardingData(data.onboarding),
  actualAddress: data.actualAddress,
  legalAddress: data.legalAddress,
  isLicensed: data.isLicensed ?? undefined,
  taxResidences: data.taxResidences
    ? lodash.compact(data.taxResidences).map(parseLegalClientTaxResidency)
    : undefined,
  stateRegistration: parseLegalClientBranchStateRegistration(
    data.stateRegistration
  ),
  contacts: parseLegalClientBranchContacts(data.contacts),
  licenseInfo: parseLegalClientBranchLicenseInfo(data.licenseInfo),
  additionalInformationFiles: lodash
    .compact(data.additionalInformationDocuments)
    .map(parseFileDocumentEntity),
});
