import { RelationType } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Stack, Typography } from 'libs/ui';
import {
  Field,
  useFormContext,
} from 'modules/client/common/forms/pepConnectionForm';
import { PepConnectionRelationPath } from 'modules/client/common/helpers';

export const FormContent = () => {
  const { values } = useFormContext();
  const { t } = useTranslation();

  const isYes =
    values.pepConnection.relation?.value === RelationType.yesSpouses;

  return (
    <>
      <Field.Autocomplete
        label={t('client.client.pepConnection.relation.label')}
        name="pepConnection.relation"
        optionLabelKey="label"
        options={Object.values(RelationType).map((v) => ({
          label: t(PepConnectionRelationPath[v]),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t('client.client.pepConnection.relation.placeholder')}
        variant="select"
      />

      {isYes && (
        <Stack spacing={24}>
          <Typography variant="subtitle">
            {t('client.client.pepConnection.infoRelationTitle')}
          </Typography>
          <Field.Text
            label={t('client.client.pepConnection.firstName.label')}
            name="pepConnection.firstname"
            placeholder={t('client.client.pepConnection.firstName.placeholder')}
            trim="start"
          />
          <Field.Text
            label={t('client.client.pepConnection.lastName.label')}
            name="pepConnection.lastname"
            placeholder={t('client.client.pepConnection.lastName.placeholder')}
            trim="start"
          />
          <Field.Text
            label={t('client.client.pepConnection.middleName.label')}
            name="pepConnection.surname"
            placeholder={t(
              'client.client.pepConnection.middleName.placeholder'
            )}
            trim="start"
          />
          <Field.Text
            label={t('client.client.pepConnection.workName.label')}
            name="pepConnection.workName"
            placeholder={t('client.client.pepConnection.workName.label')}
          />
          <Field.Text
            label={t('client.client.pepConnection.position.label')}
            name="pepConnection.position"
            placeholder={t('client.client.pepConnection.position.label')}
          />
        </Stack>
      )}
    </>
  );
};
