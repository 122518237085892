import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientBeneficiarySnapshotQuery } from 'api/generated/graphql';
import {
  useError,
  parseLegalClientBeneficiarySnapshotEntity,
} from 'api/helpers';

import { RequestOptions } from '../types';

export const useLegalClientBeneficiarySnapshot = (
  beneficiaryId: string,
  versionId: string,
  options: RequestOptions = {}
) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useLegalClientBeneficiarySnapshotQuery({
    variables: { id: beneficiaryId, versionId },
    ...options,
  });

  const error = useError(apolloError);

  const snapshot = useMemo(
    () =>
      data?.legalClientBeneficiarySnapshot
        ? parseLegalClientBeneficiarySnapshotEntity(
            data.legalClientBeneficiarySnapshot
          )
        : null,
    [data?.legalClientBeneficiarySnapshot]
  );

  return {
    snapshot,
    loading,
    error,
    refetch,
  };
};
