export enum KycStatusType {
  progress = 'progress',
  rejected = 'rejected',
  success = 'success',
  notStarted = 'notStarted',
}

export enum KycRejectType {
  AgeRequirementMismatch = 'AGE_REQUIREMENT_MISMATCH',
  Blacklist = 'BLACKLIST',
  Blocklist = 'BLOCKLIST',
  DocumentTemplate = 'DOCUMENT_TEMPLATE',
  Duplicate = 'DUPLICATE',
  ExperienceRequirementMismatch = 'EXPERIENCE_REQUIREMENT_MISMATCH',
  Foreigner = 'FOREIGNER',
  Forgery = 'FORGERY',
  FraudulentLiveness = 'FRAUDULENT_LIVENESS',
  FraudulentPatterns = 'FRAUDULENT_PATTERNS',
  InconsistentProfile = 'INCONSISTENT_PROFILE',
  RegulationsViolations = 'REGULATIONS_VIOLATIONS',
  SelfieMismatch = 'SELFIE_MISMATCH',
  Spam = 'SPAM',
  ThirdPartyInvolved = 'THIRD_PARTY_INVOLVED',
  UnsupportedLanguage = 'UNSUPPORTED_LANGUAGE',
  WrongUserRegion = 'WRONG_USER_REGION',
  Criminal = 'CRIMINAL',
  CompromisedPersons = 'COMPROMISED_PERSONS',
  Pep = 'PEP',
  AverseMedia = 'ADVERSE_MEDIA',
  CHECK_UNAVAILABLE = 'CHECK_UNAVAILABLE',
  DB_DATA_MISMATCH = 'DB_DATA_MISMATCH',
  DB_DATA_NOT_FOUND = 'DB_DATA_NOT_FOUND',
}

export interface KycEntity {
  kycStatus?: KycStatusType;
  rejectReason?: KycRejectType[];
  riskLabels?: string;
}
