import { CountryEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Section } from 'modules/client/common/components';
import { Field } from 'modules/client/common/forms/placeOfBirthForm';

interface Props {
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const PlaceOfBirthSection = ({ countriesLoading, countries }: Props) => {
  const { t } = useTranslation();

  return (
    <Section title={t('client.client.placeOfBirth.title')}>
      <Field.Autocomplete
        label={t('client.client.placeOfBirth.country.label')}
        loading={countriesLoading}
        name="placeOfBirth.country"
        optionLabelKey="name"
        options={countries}
        optionValueKey="id"
        placeholder={t('client.client.placeOfBirth.country.placeholder')}
      />
      <Field.Text
        label={t('client.client.placeOfBirth.locality.label')}
        name="placeOfBirth.locality"
        placeholder={t('client.client.placeOfBirth.locality.placeholder')}
      />
    </Section>
  );
};
