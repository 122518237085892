import { NavigationLink } from 'components/NavigationLink';
import { TranslationPath, useTranslation } from 'libs/i18n';
import { useMatches, RoutesId } from 'libs/navigation/';
import { Breadcrumbs, Typography } from 'libs/ui';
import { Colors } from 'libs/ui/theme';

const breadcrumbsPages: Partial<Record<RoutesId, TranslationPath>> = {
  [RoutesId.admins]: 'breadcrumbsPages.admins',
  [RoutesId.personClients]: 'breadcrumbsPages.clients.personal',
  [RoutesId.legalClients]: 'breadcrumbsPages.clients.legal',
};

const excludedPages = [RoutesId.root, RoutesId.clients];

export type NavigationBreadcrumbsTitles = Partial<Record<RoutesId, string>>;

export interface NavigationBreadcrumbsProps {
  titles?: NavigationBreadcrumbsTitles;
}

/**
 * Navigation breadcrumbs component.
 */
export const NavigationBreadcrumbs = ({
  titles,
}: NavigationBreadcrumbsProps) => {
  const matches = useMatches();

  const { t } = useTranslation();

  const breadcrumbs = matches
    .filter(({ id }) => !excludedPages.includes(id))
    .map((route) => (
      <NavigationLink to={route.pathname}>
        <Typography color={Colors.gray300} variant="captionMedium">
          {titles?.[route.id] ??
            (breadcrumbsPages[route.id]
              ? t(breadcrumbsPages[route.id]!)
              : undefined)}
        </Typography>
      </NavigationLink>
    ));

  return <Breadcrumbs breadcrumbs={breadcrumbs} />;
};
