import { OccupationType } from 'api/types/entity';
import { UpdateRepresentativeClientParams } from 'api/types/params';
import { lodash } from 'helpers';
import {
  parseAdditionalInformationFileIdsParam,
  parseAddressParam,
  parseTaxResidencyParam,
  parsePepConnectionParam,
  parseBiographyParam,
  parseCitizenshipsParam,
  parseAdditionalDocumentFileIdsParam,
} from 'modules/client/common/helpers';
import { parseIdentityDocumentParam } from 'modules/client/common/helpers/parseParams/parseIdentityDocumentParam';

import { RepresentativeFormType } from '../../forms/representativeForm';

import { getBankAccountsParam } from './getBankAccountsParam';
import { getDebtsParam } from './getDebtsParam';

export const getSubmitData = (
  versionNumber: number,
  values: RepresentativeFormType
): UpdateRepresentativeClientParams => ({
  versionNumber,
  firstName: values.personalData.firstName,
  lastName: values.personalData.lastName,
  middleName: values.personalData.middleName ?? null,
  dateOfBirth: values.personalData.dateOfBirth,
  bankAccounts: getBankAccountsParam(values),
  biography: parseBiographyParam(values),
  citizenships: parseCitizenshipsParam(values),
  debts: getDebtsParam(
    values,
    values.biography?.occupation?.value === OccupationType.entrepreneur
  ),

  ...parseAddressParam(values),

  taxResidences: parseTaxResidencyParam(values),

  pepConnection: values.pepConnection.relation?.value!,
  connectedPepInfo: parsePepConnectionParam(values),

  powerOfAttorney: {
    dateOfExpiration: values.powerOfAttorney.dateOfExpiration,
    dateOfIssue: values.powerOfAttorney.dateOfIssue,
    fileIds: lodash.compact(values.powerOfAttorney.files.map((v) => v.id)),
    documentNumber: values.powerOfAttorney.documentNumber || undefined,
    notary:
      values.powerOfAttorney.notaryFullName ||
      values.powerOfAttorney.notaryLicenseInfo
        ? {
            fullName: values.powerOfAttorney.notaryFullName,
            licenseInfo: values.powerOfAttorney.notaryLicenseInfo,
          }
        : undefined,
  },

  additionalInformationFileIds: parseAdditionalInformationFileIdsParam(
    values.additionalDocumentsFiles
  ),

  additionalDocumentFileIds: parseAdditionalDocumentFileIdsParam(
    values.documentsFiles
  ),

  identityDocument: parseIdentityDocumentParam(values),
});
