import { ErrorLayout, Layout, LoadingLayout } from 'components';

import { useRepresentativeClientVersion } from '../hooks';
import { RepresentativeClientVersionView } from '../views/RepresentativeClientVersionView';

export const RepresentativeClientVersionContainer = () => {
  const {
    initialValues,
    loading,
    error,
    versions,
    breadcrumbsTitles,
    snapshot,
    fullName,
    snapshotLoading,
    versionId,
    onBack,
    onChangeClientType,
    onChangeVersion,
    onCloseVersionHistory,
  } = useRepresentativeClientVersion();

  if (error) {
    return (
      <Layout>
        <ErrorLayout />
      </Layout>
    );
  }

  if (loading) {
    return (
      <Layout>
        <LoadingLayout />
      </Layout>
    );
  }

  return (
    <RepresentativeClientVersionView
      breadcrumbsTitles={breadcrumbsTitles}
      countries={[]}
      fullName={fullName}
      initialValues={initialValues}
      kycData={snapshot?.value.kyc}
      snapshotLoading={snapshotLoading}
      versionId={versionId}
      versions={versions ?? []}
      onBack={onBack}
      onChangeClientType={onChangeClientType}
      onChangeVersion={onChangeVersion}
      onCloseVersionHistory={onCloseVersionHistory}
    />
  );
};
