import { useMemo } from 'react';

import { ErrorLayout, Layout, LoadingLayout } from 'components';
import { filterVisibleOnboardingStatuses } from 'modules/client/common/helpers/filterVisibleOnboardingStatuses';

import { useClientVersion } from '../hooks';
import { ClientVersionView } from '../views/ClientVersionView';

export const ClientVersionContainer = () => {
  const {
    initialValues,
    loading,
    error,
    versions,
    breadcrumbsTitles,
    client,
    snapshot,
    fullName,
    snapshotLoading,
    versionId,
    hasRepresentative,
    onChangeClientType,
    onChangeVersion,
    onCloseVersionHistory,
  } = useClientVersion();

  const visibleOnboardingStatuses = useMemo(
    () => filterVisibleOnboardingStatuses(snapshot?.value.onboarding?.statuses),
    [snapshot?.value.onboarding?.statuses]
  );

  if (error) {
    return (
      <Layout>
        <ErrorLayout />
      </Layout>
    );
  }

  if (loading) {
    return (
      <Layout>
        <LoadingLayout />
      </Layout>
    );
  }

  return (
    <ClientVersionView
      breadcrumbsTitles={breadcrumbsTitles}
      countries={[]}
      currentAccountStatus={snapshot?.value.accountStatus}
      currentOnboardingStatus={snapshot?.value.onboardingStatus}
      fullName={fullName}
      hasRepresentative={hasRepresentative}
      initialValues={initialValues}
      kycData={snapshot?.value.kyc}
      managers={[]}
      onboardingData={client?.onboarding}
      snapshotLoading={snapshotLoading}
      versionId={versionId}
      versions={versions ?? []}
      visibleOnboardingStatuses={visibleOnboardingStatuses}
      onChangeClientType={onChangeClientType}
      onChangeVersion={onChangeVersion}
      onCloseVersionHistory={onCloseVersionHistory}
    />
  );
};
