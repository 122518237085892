import { format } from 'date-fns';

export const ISO_DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_FORMAT = 'dd.MM.yyyy';
export const DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm:ss';
export const TIME_FORMAT = 'HH:mm:ss';

export const formatToISODate = (date: Date): string =>
  format(date, ISO_DATE_FORMAT);

export const formatToDate = (date: Date): string => format(date, DATE_FORMAT);

export const formatToDateTime = (date: Date): string =>
  format(date, DATE_TIME_FORMAT);

export const formatToTime = (date: Date): string => format(date, TIME_FORMAT);
