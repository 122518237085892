import { LegalClientBeneficiaryEntity } from 'api/types/entity';
import { formatToISODate, yesOrNotOption } from 'helpers';
import { TFunc } from 'libs/i18n';
import {
  parseAddressValue,
  parseTaxResidencesValue,
  parsePepConnectionInitialValue,
  parseIdentityDocumentInitialValue,
} from 'modules/client/common/helpers';

import { ControlOverFormType } from '../../forms/controlOverForm';
import { LegalClientBeneficiaryFormType } from '../../forms/legalClientBeneficiaryForm';

import { parseDocumentsFiles } from './parseDocumentsFiles';

export const getLegalClientBeneficiaryInitialValue = (
  client: LegalClientBeneficiaryEntity,
  t: TFunc
): LegalClientBeneficiaryFormType => ({
  contacts: {
    email: client.email,
  },

  placeOfBirth: {
    country: client.placeOfBirth?.country ?? null,
    locality: client.placeOfBirth?.locality ?? '',
  },

  personalData: {
    firstName: client.firstName,
    lastName: client.lastName,
    middleName: client.middleName,
    dateOfBirth: client.dateOfBirth,
  },

  citizenships: client.citizenships ?? [null],

  taxResidences: parseTaxResidencesValue(client.taxResidences),

  registrationAddress: parseAddressValue(client.registrationAddress),
  residentialAddress: parseAddressValue(client.residentialAddress),

  pepConnection: parsePepConnectionInitialValue(client.pepConnection, t),

  controlOver: client.controlOver
    ? {
        directCapitalParticipationPercentage:
          client.controlOver.directCapitalParticipationPercentage,
        financialConditionAmount: client.controlOver.financialConditionAmount,
        hasControlOverLegalClient:
          yesOrNotOption(t, client.controlOver.hasControlOverLegalClient) ??
          null,
        indirectCapitalParticipationPercentage:
          client.controlOver.indirectCapitalParticipationPercentage,
      }
    : ({} as ControlOverFormType['controlOver']),

  activity: {
    id: client.id,
    createdAt: formatToISODate(client.createdAt),
  },

  identityDocument: parseIdentityDocumentInitialValue(
    t,
    client.identityDocument
  ),
  additionalDocumentsFiles: client.additionalInformationFiles,
  documentsFiles: parseDocumentsFiles(client),
});
