import { CSSObject } from 'styled-components';

import { styled } from '../styled';
import { Colors } from '../theme';

interface Props {
  isCellClickable: boolean;
  overlayWrapperHeight?: number;
}

export const TableStyled = styled('div')<Props>(
  ({ theme, isCellClickable, overlayWrapperHeight = 156 }) => ({
    width: '100%',
    position: 'relative',

    '.table-dataGrid': {
      borderRadius: 16,

      border: `1px solid ${Colors.gray150}`,
      background: Colors.white,

      '.table-main': {
        overflow: 'unset',
      },

      '.table-virtualScroller': {
        marginTop: '0!important',
      },

      '.table-columnHeaders': {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        background: Colors.gray50,
        height: 44,
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        borderColor: Colors.gray150,

        '.table-columnHeaderTitle': {
          fontSize: 12,
          fontWeight: 500,
          color: Colors.gray250,
        } as CSSObject,

        '.table-columnSeparator': {
          display: 'none',
        } as CSSObject,
      } as CSSObject,

      '.table-cell': {
        ...theme.typography.body,
      },

      '.table-row:hover': {
        cursor: isCellClickable ? 'pointer' : 'auto',
      },

      '.table-columnHeader:not(:first-child), .table-cell:not(:first-child)': {
        borderLeft: `1px solid  ${Colors.gray150}`,
        borderColor: Colors.gray150,
      } as CSSObject,

      '.table-columnHeader, .table-cell': {
        padding: '0 16px',
      } as CSSObject,

      '.table-columnHeader:focus, .table-cell:focus, .table-columnHeader:focus-within, .table-cell:focus-within':
        {
          outline: 'none',
        } as CSSObject,

      '.table-row--lastVisible .table-cell': {
        borderBottom: 0,
        minHeight: 70,
      } as CSSObject,
      '.table-row--lastVisible': {
        minHeight: 70,
      } as CSSObject,

      '.table-footerContainer': {
        borderColor: Colors.gray150,
        height: 66,
      } as CSSObject,
    } as CSSObject,

    '.table-overlayWrapper': {
      minHeight: overlayWrapperHeight,
      ...theme.typography.bodyMedium,
    } as CSSObject,
  })
);
