import { useTranslation } from 'libs/i18n';
import { Section } from 'modules/client/common/components';
import { Field } from 'modules/client/common/forms/companyNameForm';

interface Props {
  hideShortName?: boolean;
  title?: string;
  showEn?: boolean;
}

export const CompanyNameSection = ({ title, hideShortName, showEn }: Props) => {
  const { t } = useTranslation();

  return (
    <Section title={title ?? t('client.legal.companyName.title')}>
      <Field.Text
        label={t('client.legal.companyName.fullName.label')}
        name="companyName.fullName"
        placeholder={t('client.legal.companyName.fullName.placeholder')}
      />

      {showEn && (
        <Field.Text
          label={t('client.legal.companyName.fullNameEng.label')}
          name="companyName.fullNameEng"
          placeholder={t('client.legal.companyName.fullNameEng.placeholder')}
        />
      )}

      {!hideShortName && (
        <>
          <Field.Text
            fullWidth
            label={t('client.legal.companyName.shortName.label')}
            name="companyName.shortName"
            placeholder={t('client.legal.companyName.shortName.placeholder')}
          />
          {showEn && (
            <Field.Text
              label={t('client.legal.companyName.shortNameEng.label')}
              name="companyName.shortNameEng"
              placeholder={t(
                'client.legal.companyName.shortNameEng.placeholder'
              )}
            />
          )}
        </>
      )}
    </Section>
  );
};
