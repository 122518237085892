import { useMemo } from 'react';

import { LegalClientInnerItemEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Section } from 'modules/client/common/components';

import { HeadItem, HeadsTable } from './HeadsTable';

interface Props {
  firstHeads: LegalClientInnerItemEntity[];
  onClickHead: (id: string) => void;
}

export const CompanyFirstHeadsSection = ({
  firstHeads,
  onClickHead,
}: Props) => {
  const { t } = useTranslation();

  const items: HeadItem[] = useMemo(() => {
    const result = firstHeads.map((h) => ({
      id: h.id,
      name: h.name,
      city: h.registrationCity || '',
      location: h.location || '',
      email: h.email || '',
      createdAt: h.createdAt,
    }));

    result.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

    return result;
  }, [firstHeads]);

  const onCellClick = (item: HeadItem) => {
    if (item) {
      onClickHead(item.id);
    }
  };

  return (
    <Section title={t('client.legal.legalClientLegalHead.firstHeads.title')}>
      <HeadsTable items={items} onCellClick={onCellClick} />
    </Section>
  );
};
