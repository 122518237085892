import { PropsWithChildren } from 'react';

import { LayoutProps } from 'components/Layout/Layout';
import {
  NavigationBreadcrumbs,
  NavigationBreadcrumbsProps,
} from 'components/NavigationBreadcrumbs';
import { Stack } from 'libs/ui';

import { LayoutStyled } from './styles';

interface Props
  extends LayoutProps,
    NavigationBreadcrumbsProps,
    PropsWithChildren {}

export const LayoutWithNavigationBreadcrumbs = ({
  children,
  titles,
  ...layoutProps
}: Props) => (
  <LayoutStyled {...layoutProps}>
    <Stack spacing={36}>
      <NavigationBreadcrumbs titles={titles} />
      {children}
    </Stack>
  </LayoutStyled>
);
