import { ErrorLayout, Layout, LoadingLayout } from 'components';

import { useLegalClient, useLegalClientStatus } from '../hooks';
import { LegalClientView } from '../views';

export const LegalClientContainer = () => {
  const {
    legalClient,
    loading,
    countries,
    countriesLoading,
    error,
    initialValues,
    submitLoading,
    formError,
    breadcrumbsTitles,
    managers,
    managersLoading,
    onSubmit,
    onClickHead,
    onClickLegalHead,
    onClickBeneficiary,
    onClickBranch,
    onChangeClientType,
    lockClientIfPossible,
    unlockClient,
    onShowVersionHistory,
  } = useLegalClient();

  const {
    fetchAvailableStatuses: fetchStatusesForChange,
    loading: loadingStatuses,
    submitLoading: submitLoadingStatus,
    onChangeOnboardingStatus,
    statuses: statusesForChange,
    onChangeAccountStatus,
    visibleOnboardingStatuses,
  } = useLegalClientStatus(legalClient);

  if (error || (!legalClient && !loading)) {
    return (
      <Layout>
        <ErrorLayout />
      </Layout>
    );
  }

  if (loading || !legalClient) {
    return (
      <Layout>
        <LoadingLayout />
      </Layout>
    );
  }

  return (
    <LegalClientView
      beneficiary={{
        unidentifiableBeneficiaryLetterFiles:
          legalClient.unidentifiableBeneficiaryLetterFiles,
        beneficiaries: legalClient.beneficiaries ?? [],
      }}
      branches={legalClient.branches ?? []}
      breadcrumbsTitles={breadcrumbsTitles}
      countries={countries}
      countriesLoading={countriesLoading}
      currentAccountStatus={legalClient.accountStatus}
      currentOnboardingStatus={legalClient.onboardingStatus}
      fetchStatusesForChange={fetchStatusesForChange}
      heads={legalClient.heads ?? []}
      initialErrors={formError}
      initialValues={initialValues}
      legalHeads={legalClient.legalHeads ?? []}
      loadingStatuses={loadingStatuses}
      lockClientIfPossible={lockClientIfPossible}
      managers={managers}
      managersLoading={managersLoading}
      representativeOnboardingData={legalClient.representativeOnboarding}
      statusesForChange={statusesForChange}
      submitLoading={submitLoading}
      submitLoadingStatus={submitLoadingStatus}
      unlockClient={unlockClient}
      visibleOnboardingStatuses={visibleOnboardingStatuses}
      onChangeAccountStatus={onChangeAccountStatus}
      onChangeClientType={onChangeClientType}
      onChangeOnboardingStatus={onChangeOnboardingStatus}
      onClickBeneficiary={onClickBeneficiary}
      onClickBranch={onClickBranch}
      onClickHead={onClickHead}
      onClickLegalHead={onClickLegalHead}
      onShowVersionHistory={onShowVersionHistory}
      onSubmit={onSubmit}
    />
  );
};
