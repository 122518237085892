import { OccupationType } from 'api/types/entity';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
} from 'libs/form';
import { TFunc } from 'libs/i18n';

import { AccountCodesFormType, accountCodesSchema } from './accountCodesForm';
import { ActivityFormType, activitySchema } from './activityForm';
import {
  AdditionalDocumentsFormType,
  getAdditionalDocumentsSchema,
} from './additionalDocumentsForm';
import { BankAccountsFormType, bankAccountsSchema } from './bankAccountsForm';
import { BiographyFormType, biographySchema } from './biographyForm';
import { CitizenshipFormType, citizenshipsSchema } from './citizenshipForm';
import { ContactsFormType, getContactsSchema } from './contactsForm';
import { DebtsFormType, getDebtsSchema } from './debtsForm';
import { DocumentsFormType, documentsSchema } from './documentsForm';
import {
  FinancingSourcesFormType,
  getFinancingSourcesSchema,
} from './financingSourcesForm';
import { GeneralFormType, generalSchema } from './generalForm';
import {
  IdentityDocumentFormType,
  identityDocumentSchema,
} from './identityDocumentForm';
import {
  OpenAccountOrderFormType,
  openAccountOrderSchema,
} from './openAccountOrderForm';
import {
  OrderSubmissionContactsFormType,
  getOrderSubmissionContactsSchema,
} from './orderSubmissionContactsForm';
import {
  PepConnectionFormType,
  getPepConnectionSchema,
} from './pepConnectionForm';
import {
  PersonalDataFormType,
  getPersonalDataSchema,
} from './personalDataForm';
import { PlaceOfBirthFormType, placeOfBirthSchema } from './placeOfBirthForm';
import {
  RegistrationAddressFormType,
  registrationAddressSchema,
} from './registrationAddressForm';
import {
  ResidentialAddressFormType,
  residentialAddressSchema,
} from './residentialAddressForm';
import { RiskLevelFormType, riskLevelSchema } from './riskLevelForm';
import {
  ServiceContractFormType,
  serviceContractSchema,
} from './serviceContractForm';
import { StatusesFormType, statusesSchema } from './statusesForm';
import { TaxResidencyFormType, taxResidencesSchema } from './taxResidencyForm';
import {
  WithdrawalAccountsFormType,
  withdrawalAccountsSchema,
} from './withdrawalAccountsForm';

export interface ClientFormType
  extends StatusesFormType,
    GeneralFormType,
    RiskLevelFormType,
    AccountCodesFormType,
    ServiceContractFormType,
    ActivityFormType,
    ContactsFormType,
    PersonalDataFormType,
    CitizenshipFormType,
    TaxResidencyFormType,
    PlaceOfBirthFormType,
    RegistrationAddressFormType,
    ResidentialAddressFormType,
    BiographyFormType,
    BankAccountsFormType,
    FinancingSourcesFormType,
    PepConnectionFormType,
    DebtsFormType,
    WithdrawalAccountsFormType,
    DocumentsFormType,
    AdditionalDocumentsFormType,
    IdentityDocumentFormType,
    OpenAccountOrderFormType,
    OrderSubmissionContactsFormType {}

export const getSchema = (
  t: TFunc,
  initialValues?: ClientFormType
): ObjectSchema<ClientFormType> =>
  object()
    .concat(statusesSchema)
    .concat(generalSchema)
    .concat(accountCodesSchema)
    .concat(serviceContractSchema)
    .concat(activitySchema)
    .concat(riskLevelSchema)
    .concat(openAccountOrderSchema)
    .concat(getContactsSchema(t))
    .concat(getPersonalDataSchema(t))
    .concat(citizenshipsSchema)
    .concat(taxResidencesSchema)
    .concat(registrationAddressSchema)
    .concat(residentialAddressSchema)
    .concat(placeOfBirthSchema)
    .concat(biographySchema)
    .concat(bankAccountsSchema)
    .concat(identityDocumentSchema)
    .concat(getFinancingSourcesSchema(t))
    .concat(getPepConnectionSchema(t))
    .concat(
      getDebtsSchema(
        initialValues?.biography?.occupation?.value ===
          OccupationType.entrepreneur
      )
    )
    .concat(documentsSchema)
    .concat(getAdditionalDocumentsSchema())
    .concat(getOrderSubmissionContactsSchema(t))
    .concat(withdrawalAccountsSchema);

export const { Field, Form, useFormContext, Submit } =
  TypedForm<ClientFormType>();

export type FormErrors = FormErrorsBase<ClientFormType>;
