import { object, ObjectSchema, TypedForm } from 'libs/form';

import { companyRegistrationAddressObject } from './companyRegistrationAddressForm';
import { AddressFormType } from './registrationAddressForm';

export interface CompanyResidentialAddressFormType {
  companyResidentialAddress: AddressFormType;
}

export const companyResidentialAddressSchema: ObjectSchema<CompanyResidentialAddressFormType> =
  object({
    companyResidentialAddress: companyRegistrationAddressObject,
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyResidentialAddressFormType>();

export { companyRegistrationAddressObject };
