import { ErrorLayout, Layout, LoadingLayout } from 'components';
import { useScrollToTop } from 'hooks';

import { useLegalClientFirstHeadVersion } from '../hooks';
import { LegalClientFirstHeadVersionView } from '../views';

export const LegalClientFirstHeadVersionContainer = () => {
  const {
    initialValues,
    loading,
    error,
    versions,
    breadcrumbsTitles,
    snapshot,
    fullName,
    snapshotLoading,
    versionId,
    onBack,
    onChangeVersion,
    onCloseVersionHistory,
  } = useLegalClientFirstHeadVersion();

  useScrollToTop([]);

  if (error) {
    return (
      <Layout>
        <ErrorLayout />
      </Layout>
    );
  }

  if (loading) {
    return (
      <Layout>
        <LoadingLayout />
      </Layout>
    );
  }

  return (
    <LegalClientFirstHeadVersionView
      breadcrumbsTitles={breadcrumbsTitles}
      countries={[]}
      fullName={fullName}
      initialValues={initialValues}
      kycData={snapshot?.value.kyc}
      snapshotLoading={snapshotLoading}
      versionId={versionId}
      versions={versions ?? []}
      onBack={onBack}
      onChangeVersion={onChangeVersion}
      onCloseVersionHistory={onCloseVersionHistory}
    />
  );
};
