import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';

import { Field } from '../../../../forms/controlOverForm';

export interface FormContentProps {}

export const FormContent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Field.Autocomplete
        label={t(
          'client.legal.legalClientBeneficiary.controlOver.hasControlOverLegalClient.label'
        )}
        name="controlOver.hasControlOverLegalClient"
        optionLabelKey="label"
        options={getYesOrNoOptions(t)}
        optionValueKey="value"
        placeholder={t('common.selectPlaceholder')}
        variant="select"
      />
      <Field.Number
        format={(v) => (!v ? v : Number(v).toString())}
        label={t(
          'client.legal.legalClientBeneficiary.controlOver.directCapitalParticipationPercentage.label'
        )}
        maxNumber={100}
        name="controlOver.directCapitalParticipationPercentage"
        placeholder={t(
          'client.legal.legalClientBeneficiary.controlOver.directCapitalParticipationPercentage.placeholder'
        )}
      />

      <Field.Number
        format={(v) => (!v ? v : Number(v).toString())}
        label={t(
          'client.legal.legalClientBeneficiary.controlOver.indirectCapitalParticipationPercentage.label'
        )}
        maxNumber={100}
        name="controlOver.indirectCapitalParticipationPercentage"
        placeholder={t(
          'client.legal.legalClientBeneficiary.controlOver.indirectCapitalParticipationPercentage.placeholder'
        )}
      />

      <Field.Price
        label={t(
          'client.legal.legalClientBeneficiary.controlOver.financialConditionAmount.label'
        )}
        name="controlOver.financialConditionAmount"
        placeholder={t(
          'client.legal.legalClientBeneficiary.controlOver.financialConditionAmount.placeholder'
        )}
      />
    </>
  );
};
