import { ObjectSchema, string, object, TypedForm } from 'libs/form';

export interface BaseActivityFormType {
  activity: {
    id?: string;
    createdAt?: string;
  };
}
export interface ActivityFormType {
  activity: BaseActivityFormType['activity'] & {
    lastSessionDate?: string;
    ip?: string;
    userAgent?: string;
  };
}

export const baseActivitySchema: ObjectSchema<BaseActivityFormType> = object({
  activity: object({
    id: string(),
    createdAt: string(),
  }).default({}),
});

export const activitySchema: ObjectSchema<ActivityFormType> = object({
  activity: object({
    id: string(),
    createdAt: string(),
    lastSessionDate: string(),
    ip: string(),
    userAgent: string(),
  }).default({}),
});

export const { Field, Form, useFormContext, Submit } =
  TypedForm<ActivityFormType>();
