import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLockLegalClientRepresentativeMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useLockLegalClientRepresentative = () => {
  const [handle, { loading, error: apolloError }] =
    useLockLegalClientRepresentativeMutation();

  const error = useError(apolloError);

  return {
    loading,
    error,
    lock: useCallback(
      async (id: string, versionNumber: number) => {
        const res = await handle({ variables: { id, versionNumber } });
        return !!res.data?.lock;
      },
      [handle]
    ),
  };
};
