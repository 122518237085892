import {
  useLegalClient,
  useLegalClientLegalHead as useLegalClientLegalHeadApi,
} from 'api/requests';
import { useCountries } from 'api/requests/country';
import { NavigationBreadcrumbsTitles } from 'components';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { ROUTES, useNavigate, RoutesId } from 'libs/navigation';

import { LegalClientLegalHeadFormType } from '../forms/legalClientLegalHeadForm';
import { getLegalClientLegalHeadInitialValue } from '../helpers';

import { useLegalHeadPageTitle } from './useLegalHeadPageTitle';
import { useLockLegalClientLegalHead } from './useLockLegalClientLegalHead';
import { useUpdateLegalClientLegalHead } from './useUpdateLegalClientLegalHead';

type NavigateParams = {
  legalClientId: string;
  headId: string;
};

export const useLegalClientLegalHead = () => {
  const { t } = useTranslation();

  const { params, navigate } = useNavigate<NavigateParams>();

  const { loading, legalClientLegalHead, error } = useLegalClientLegalHeadApi(
    params.headId!
  );

  const { loading: submitLoading, updateLegalClientLegalHead } =
    useUpdateLegalClientLegalHead();

  const { countries, loading: countriesLoading } = useCountries();

  const { legalClient } = useLegalClient(params.legalClientId!);

  const { lockClientIfPossible, unlockClient } = useLockLegalClientLegalHead(
    legalClient,
    legalClientLegalHead
  );

  useLegalHeadPageTitle(legalClient, legalClientLegalHead);

  const onSubmit: FormSubmit<LegalClientLegalHeadFormType> = async (
    values: LegalClientLegalHeadFormType,
    helpers
  ) => {
    if (await updateLegalClientLegalHead(legalClientLegalHead!, values)) {
      helpers.setReadOnly(true);
    }
  };

  const initialValues = legalClientLegalHead
    ? getLegalClientLegalHeadInitialValue(legalClientLegalHead, t)
    : undefined;

  const breadcrumbsTitles: NavigationBreadcrumbsTitles = {
    [RoutesId.legalClientLegalHead]: legalClientLegalHead?.fullName,
    [RoutesId.legalClient]: legalClient?.fullName,
  };

  const onClickHead = (firstHeadId: string) => {
    if (legalClientLegalHead?.legalClientId) {
      navigate(
        ROUTES.clients.legalClients.legalClient.legalClientLegalHead
          .legalClientFirstHead.fullPath,
        {
          legalClientId: legalClientLegalHead.legalClientId,
          headId: legalClientLegalHead.id,
          firstHeadId,
        }
      );
    }
  };

  const onShowVersionHistory =
    legalClient &&
    legalClientLegalHead?.versionNumber &&
    legalClientLegalHead?.versionNumber > 1
      ? () => {
          navigate(
            ROUTES.clients.legalClients.legalClient.legalClientVersion
              .legalClientLegalHeadVersion.fullPath,
            {
              legalClientId: legalClient.id,
              headId: params.headId!,
              versionId: legalClientLegalHead.versionId!,
            }
          );
        }
      : undefined;

  return {
    legalClientLegalHead,
    loading: !legalClientLegalHead && loading,
    countries: countries ?? [],
    countriesLoading,
    error,
    submitLoading,
    initialValues,
    breadcrumbsTitles,
    lockClientIfPossible,
    unlockClient,
    onSubmit,
    onClickHead,
    onShowVersionHistory,
  };
};
