import { PropsWithChildren } from 'react';

import { Stack, Typography } from 'libs/ui';

interface Props extends PropsWithChildren {
  title: string;
}

export const Section = ({ title, children }: Props) => (
  <Stack spacing={32}>
    <Typography variant="headline">{title}</Typography>
    {children}
  </Stack>
);
