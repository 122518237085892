import {
  useLegalClient,
  useLegalClientBranch as useLegalClientBranchApi,
} from 'api/requests';
import { useCountries } from 'api/requests/country';
import { NavigationBreadcrumbsTitles } from 'components';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { useNavigate, RoutesId } from 'libs/navigation';

import { LegalClientBranchFormType } from '../forms/legalClientBranchForm';
import { getLegalClientBranchInitialValue } from '../helpers';

import { useBranchPageTitle } from './useBranchPageTitle';
import { useLockLegalClientBranch } from './useLockLegalClientBranch';
import { useUpdateLegalClientBranch } from './useUpdateLegalClientBranch';

type NavigateParams = {
  legalClientId: string;
  branchId: string;
};

export const useLegalClientBranch = () => {
  const { t } = useTranslation();

  const { params } = useNavigate<NavigateParams>();

  const { loading, legalClientBranch, error } = useLegalClientBranchApi(
    params.branchId!
  );

  const { loading: submitLoading, updateLegalClientBranch } =
    useUpdateLegalClientBranch();

  const { countries, loading: countriesLoading } = useCountries();

  const { legalClient } = useLegalClient(params.legalClientId!);

  const { lockClientIfPossible, unlockClient } = useLockLegalClientBranch(
    legalClient,
    legalClientBranch
  );

  useBranchPageTitle(legalClient, legalClientBranch);

  const onSubmit: FormSubmit<LegalClientBranchFormType> = async (
    values,
    helpers
  ) => {
    if (await updateLegalClientBranch(legalClientBranch!, values)) {
      helpers.setReadOnly(true);
    }
  };

  const initialValues = legalClientBranch
    ? getLegalClientBranchInitialValue(legalClientBranch, t)
    : undefined;

  const breadcrumbsTitles: NavigationBreadcrumbsTitles = {
    [RoutesId.legalClientBranch]: legalClientBranch?.fullName ?? '',
    [RoutesId.legalClient]: legalClient?.fullName,
  };

  return {
    legalClientBranch,
    loading: !legalClientBranch && loading,
    countries: countries ?? [],
    countriesLoading,
    error,
    submitLoading,
    initialValues,
    breadcrumbsTitles,
    lockClientIfPossible,
    unlockClient,
    onSubmit,
  };
};
