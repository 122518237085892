import { ErrorLayout, Layout } from 'components';
import { useTranslation } from 'libs/i18n';

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <ErrorLayout subtitle={t('notFound.subtitle')} />
    </Layout>
  );
};
