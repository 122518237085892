import { useClient as useClientApi } from 'api/requests/client';
import { useCountries } from 'api/requests/country';
import { parseFullName } from 'helpers';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { ROUTES, RoutesId, useNavigate, usePageTitle } from 'libs/navigation';
import { useManagersForClients } from 'modules/client/common/hooks';

import { NavigationBreadcrumbsTitles } from '../../../../components';
import { ClientFormType } from '../forms/clientForm';
import { getClientInitialValue } from '../helpers';

import { useFormError } from './useFormError';
import { useLockClient } from './useLockClient';
import { useUpdateClient } from './useUpdateClient';

type ClientContainerParams = {
  clientId: string;
};

export const useClient = () => {
  const { t } = useTranslation();

  const { params, navigate } = useNavigate<ClientContainerParams>();

  const { loading, client, error } = useClientApi(params.clientId!);

  const { countries, loading: countriesLoading } = useCountries();

  const { updateClient, submitLoading, error: submitError } = useUpdateClient();

  const formError = useFormError(submitError);

  const { lockClientIfPossible, unlockClient } = useLockClient(client);
  const { managers, loading: managersLoading } = useManagersForClients();

  const hasRepresentative = !!client?.representativeId;

  const initialValues = client ? getClientInitialValue(client, t) : undefined;
  const fullName = client ? parseFullName(client) : '';

  const onSubmit: FormSubmit<ClientFormType> = async (values, helpers) => {
    if (await updateClient(client!, values)) {
      helpers.setReadOnly(true);
    }
  };

  const breadcrumbsTitles: NavigationBreadcrumbsTitles = {
    [RoutesId.personClient]: fullName,
  };

  const onChangeClientType = () => {
    if (client?.representativeId) {
      navigate(
        ROUTES.clients.personClients.personClient.representativeClient.fullPath,
        {
          clientId: client.id,
          representativeId: client.representativeId,
        },
        { replace: true }
      );
    }
  };

  const onShowVersionHistory =
    client?.versionNumber && client?.versionNumber > 1
      ? () => {
          navigate(
            ROUTES.clients.personClients.personClient.personClientVersion
              .fullPath,
            {
              clientId: params.clientId!,
              versionId: client.versionId!,
            },
            { replace: true }
          );
        }
      : undefined;

  usePageTitle(fullName);

  return {
    client,
    loading: !client && loading,
    countries: countries ?? [],
    countriesLoading,
    error,
    submitLoading,
    hasRepresentative,
    initialValues,
    formError,
    managers,
    managersLoading,
    breadcrumbsTitles,
    lockClientIfPossible,
    unlockClient,
    onChangeClientType,
    onSubmit,
    onShowVersionHistory,
  };
};
