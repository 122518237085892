import { useMemo } from 'react';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { Icon } from '../Icon';
import { Colors, cx } from '../theme';

import { CustomPagination } from './CustomPagination';
import { TableStyled } from './Table.styles';
import { BaseRow, TableProps } from './types';

const ArrowFilledDown = () => (
  <Icon.ArrowFilledDown
    className="table-sort-down"
    color={Colors.gray250}
    size={16}
  />
);
const ArrowFilledUp = () => (
  <Icon.ArrowFilledUp
    className="table-sort-up"
    color={Colors.gray250}
    size={16}
  />
);

/**
 * Table component.
 */
export const Table = <Row extends BaseRow, S extends string = string>({
  rows,
  columns,
  limit = 20,
  rowCount,
  className,
  loading,
  sort,
  page = 0,
  hideFooter,
  hideFooterPagination,
  noRowsLabel = '',
  overlayWrapperHeight,
  onCellClick,
  onSortChange,
  onPaginationChange,
}: TableProps<Row, S>) => {
  const tableColumns: GridColDef<Row>[] = useMemo(
    () =>
      columns.map((column) => ({
        ...column,
        valueGetter: column.renderValue
          ? (params) => column.renderValue!(params.row as unknown as Row)
          : undefined,
        renderCell: column.renderCell
          ? (params) => column.renderCell!(params.row as unknown as Row)
          : undefined,
        headerName: column.title,
        sortable: column.sortable ?? false,
        field: column.field.toString(),
      })),
    [columns]
  );

  const sortModel = useMemo(
    () => (sort ? [{ ...sort, field: sort.field.toString() }] : []),
    [sort]
  );

  hideFooter = hideFooter || limit >= (rowCount ?? 0);

  return (
    <TableStyled
      className={cx('table', className)}
      isCellClickable={!!onCellClick}
      overlayWrapperHeight={overlayWrapperHeight}
    >
      <DataGrid
        disableColumnFilter
        disableColumnMenu
        disableRowSelectionOnClick
        hideFooterSelectedRowCount
        classes={{
          main: 'table-main',
          columnHeaders: 'table-columnHeaders',
          columnHeader: 'table-columnHeader',
          columnHeaderTitle: 'table-columnHeaderTitle',
          row: 'table-row',
          'row--lastVisible': 'table-row--lastVisible',
          cell: 'table-cell',
          columnSeparator: 'table-columnSeparator',
          footerContainer: 'table-footerContainer',
          overlayWrapper: 'table-overlayWrapper',
          virtualScroller: 'table-virtualScroller',
        }}
        className="table-dataGrid"
        columnHeaderHeight={44}
        columns={tableColumns}
        hideFooter={hideFooter}
        hideFooterPagination={hideFooterPagination}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: limit,
              page,
            },
          },
        }}
        loading={loading}
        localeText={{ noRowsLabel }}
        pageSizeOptions={[limit]}
        paginationMode="server"
        paginationModel={{ page, pageSize: limit }}
        rowBuffer={limit}
        rowCount={rowCount}
        // @ts-ignore
        rows={rows}
        slotProps={{
          baseIconButton: { disableRipple: true },
        }}
        slots={{
          columnSortedAscendingIcon: ArrowFilledDown,
          columnSortedDescendingIcon: ArrowFilledUp,
          pagination: CustomPagination,
        }}
        sortingMode="server"
        sortModel={sortModel}
        onCellClick={(params) => {
          if (window?.getSelection?.()?.type !== 'Range') {
            onCellClick?.(params.row as unknown as Row);
          }
        }}
        onPaginationModelChange={(p) => onPaginationChange?.(p.page)}
        onSortModelChange={(s) =>
          onSortChange?.(
            s?.[0] ? { field: s[0].field as S, sort: s[0].sort } : undefined
          )
        }
      />
    </TableStyled>
  );
};
