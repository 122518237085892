import {
  useLockLegalClientHead as useLockApi,
  useUnlockLegalClientHead,
} from 'api/requests';
import { LegalClientEntity, LegalClientHeadEntity } from 'api/types/entity';
import { useLockClientIfPossible } from 'modules/client/common/hooks';

export const useLockLegalClientHead = (
  legalClient: LegalClientEntity | null,
  head: LegalClientHeadEntity | null
) => {
  const { lockClientIfPossible } = useLockClientIfPossible(
    {
      id: head?.id,
      versionNumber: head?.versionNumber,
      onboardingStatus: legalClient?.onboardingStatus,
    },
    useLockApi
  );

  const { unlock } = useUnlockLegalClientHead();
  const unlockClient = () => unlock(head?.id!);

  return { lockClientIfPossible, unlockClient };
};
