import { subYears } from 'date-fns';
import { isValidName } from 'helpers';
import { ObjectSchema, string, object, TypedForm, date } from 'libs/form';
import { TFunc } from 'libs/i18n';

export interface PersonalDataFormType {
  personalData: {
    firstName: string;
    lastName: string;
    middleName?: string;
    dateOfBirth: Date;
  };
}

export const registrationDateMax = subYears(new Date(), 100);
export const dateOfBirthMaxDate = subYears(new Date(), 18);
export const dateOfBirthMinDate = subYears(new Date(), 100);

export const getPersonalDataSchema = (
  t: TFunc
): ObjectSchema<PersonalDataFormType> =>
  object({
    personalData: object({
      firstName: string()
        .max(20)
        .required()
        .test('ischars', t('validation.noSpecialCharsAndNumber'), isValidName),
      lastName: string()
        .max(20)
        .required()
        .test('ischars', t('validation.noSpecialCharsAndNumber'), isValidName),
      middleName: string()
        .max(20)
        .test('ischars', t('validation.noSpecialCharsAndNumber'), isValidName),

      dateOfBirth: date()
        .min(
          dateOfBirthMinDate,
          t('client.client.personalData.dateOfBirth.maxError')
        )
        .max(
          dateOfBirthMaxDate,
          t('client.client.personalData.dateOfBirth.minError')
        )
        .required(),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<PersonalDataFormType>();
