import {
  useLockClientRepresentative as useLockApi,
  useUnlockClientRepresentative,
} from 'api/requests';
import { RepresentativeClientEntity } from 'api/types/entity';
import { useLockClientIfPossible } from 'modules/client/common/hooks';

export const useLockRepresentativeClient = (
  representative: RepresentativeClientEntity | null
) => {
  const { lockClientIfPossible } = useLockClientIfPossible(
    representative,
    useLockApi
  );

  const { unlock } = useUnlockClientRepresentative();
  const unlockClient = () => unlock(representative?.id!);

  return { lockClientIfPossible, unlockClient };
};
