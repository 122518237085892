import {
  useLockLegalClientRepresentative as useLockApi,
  useUnlockLegalClientRepresentative,
} from 'api/requests';
import {
  LegalClientEntity,
  LegalClientRepresentativeEntity,
} from 'api/types/entity';
import { useLockClientIfPossible } from 'modules/client/common/hooks';

export const useLockLegalClientRepresentative = (
  legalClient: LegalClientEntity | null,
  representative: LegalClientRepresentativeEntity | null
) => {
  const { lockClientIfPossible } = useLockClientIfPossible(
    {
      id: representative?.id,
      versionNumber: representative?.versionNumber,
      onboardingStatus: legalClient?.onboardingStatus,
    },
    useLockApi
  );

  const { unlock } = useUnlockLegalClientRepresentative();
  const unlockClient = () => unlock(representative?.id!);

  return { lockClientIfPossible, unlockClient };
};
