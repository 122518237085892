import { ClientUserEntity } from 'api/types/entity/client/clientUserEntity';
import { formatToDate, formatToDateTime } from 'helpers';
import { parseUserAgent } from 'helpers/parseUserAgent';

import { ActivityFormType } from '../../forms/activityForm';

const userAgent = (ua?: string) => {
  if (!ua) return ua;
  const { browserName, osName } = parseUserAgent(ua);
  if (osName) {
    return `${osName} - ${browserName}`;
  }
  return browserName;
};

interface Client {
  id: string;
  user: ClientUserEntity;
}

export const parseActivityInitialValue = (client: Client) => {
  const values: ActivityFormType['activity'] = {
    id: client.id,
    createdAt: formatToDate(new Date(client.user.createdAt)),
    ip: client.user.lastAuthSessionLog?.ip,
    lastSessionDate: client.user.lastAuthSessionLog?.updatedAt
      ? formatToDateTime(new Date(client.user.lastAuthSessionLog?.updatedAt))
      : undefined,
    userAgent: userAgent(client.user.lastAuthSessionLog?.userAgent),
  };

  return values;
};
