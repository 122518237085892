// eslint-disable-next-line no-restricted-imports
import {
  ClientRepresentativeKycFragment,
  Query_ClientController_GetList_AllOf_1_List_Items_Kyc_Status as KYCStatusApi,
  KycFragment,
  Query_ClientController_GetList_AllOf_1_List_Items_Representative_Kyc_Status as RepresentativeKYCStatusApi,
} from 'api/generated/graphql';
import { KycEntity, KycRejectType, KycStatusType } from 'api/types/entity';

type ApiStatus = KYCStatusApi | RepresentativeKYCStatusApi;

const statuses: Record<ApiStatus, KycStatusType> = {
  [KYCStatusApi.Approved]: KycStatusType.success,
  [KYCStatusApi.Pending]: KycStatusType.progress,
  [KYCStatusApi.Rejected]: KycStatusType.rejected,
  [KYCStatusApi.NotStarted]: KycStatusType.notStarted,
};

export const parseKycEntity = (
  kyc?: KycFragment | ClientRepresentativeKycFragment | null
): KycEntity | undefined =>
  kyc
    ? {
        kycStatus: statuses[kyc.status],
        rejectReason: kyc.rejectLabels as unknown as KycRejectType[],
        riskLabels: kyc.riskLabels?.join(', '),
      }
    : undefined;
