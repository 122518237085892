import { subYears } from 'date-fns';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import {
  AdditionalDocumentsFormType,
  getAdditionalDocumentsSchema,
} from 'modules/client/common/forms/additionalDocumentsForm';

import { BaseActivityFormType, baseActivitySchema } from './baseActivityForm';
import { CompanyNameFormType, companyNameSchema } from './companyNameForm';
import {
  CompanyRegistrationAddressFormType,
  companyRegistrationAddressSchema,
} from './companyRegistrationAddressForm';
import {
  CompanyResidentialAddressFormType,
  companyResidentialAddressSchema,
} from './companyResidentialAddressForm';
import {
  CompanyStateRegistrationFormType,
  getCompanyStateRegistrationSchema,
} from './companyStateRegistrationForm';
import {
  CompanyTaxResidencyFormType,
  companyTaxResidencySchema,
} from './companyTaxResidencyForm';
import {
  LegalClientHeadInfoFormType,
  legalClientHeadInfoFormSchema,
} from './legalClientHeadInfoForm';

export interface LegalClientLegalHeadFormType
  extends BaseActivityFormType,
    LegalClientHeadInfoFormType,
    CompanyNameFormType,
    CompanyTaxResidencyFormType,
    CompanyStateRegistrationFormType,
    CompanyRegistrationAddressFormType,
    CompanyResidentialAddressFormType,
    AdditionalDocumentsFormType {}

export const dateOfBirthMaxDate = subYears(new Date(), 18);
export const dateOfBirthMinDate = subYears(new Date(), 100);

export const getSchema = (
  t: TFunc
): ObjectSchema<LegalClientLegalHeadFormType> =>
  object()
    .concat(baseActivitySchema)
    .concat(legalClientHeadInfoFormSchema)
    .concat(companyTaxResidencySchema)
    .concat(companyNameSchema)
    .concat(companyResidentialAddressSchema)
    .concat(companyRegistrationAddressSchema)
    .concat(getCompanyStateRegistrationSchema(t))
    .concat(getAdditionalDocumentsSchema());

export const { Field, Form, useFormContext, Submit } =
  TypedForm<LegalClientLegalHeadFormType>();

export type FormErrors = FormErrorsBase<LegalClientLegalHeadFormType>;
