import { Layout } from 'components/Layout';
import { styled } from 'libs/ui/styled';

export const LayoutStyled = styled(Layout)(() => ({
  '.twoFactorConnection-content': {
    maxWidth: 831,
    width: '100%',
    margin: 'auto',
    marginTop: 64,
  },
}));
