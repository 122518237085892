import { FC, PropsWithChildren, useEffect, useState } from 'react';

import { LoadingLayoutWrapper } from 'components';
import { ROUTES, useNavigate } from 'libs/navigation';
import { useUser } from 'libs/providers';

export const NavigationWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useUser();
  const { navigate, paths } = useNavigate();

  const [loading, setLoading] = useState(true);
  const isTwoFactorConnection = paths.includes(
    ROUTES.twoFactorConnection.fullPath
  );

  const isSetPasswordPage = paths.includes(ROUTES.setPassword.fullPath);

  const isAuthPage =
    paths.includes(ROUTES.login.fullPath) ||
    paths.includes(ROUTES.loginByToken.fullPath) ||
    isSetPasswordPage;

  const isNeedHomePage = isTwoFactorConnection || isAuthPage;

  const isTempPassword = user?.isPasswordUpdateRequired;

  const hasOtp = !!user?.otp?.verified;

  useEffect(() => {
    if (!user) {
      if (!isAuthPage) {
        navigate(
          ROUTES.login.fullPath,
          {},
          { search: { ret: window.location.href } }
        );
      }
    } else if (isTempPassword) {
      navigate(
        ROUTES.setPassword.fullPath,
        {},
        { state: { otpRequired: hasOtp } }
      );
    } else if (!hasOtp) {
      navigate(ROUTES.twoFactorConnection.fullPath, {});
    } else if (isNeedHomePage) {
      navigate(ROUTES.fullPath, {});
    }
    setLoading(false);
  }, [isAuthPage, isTempPassword, isNeedHomePage, navigate, user, hasOtp]);

  return (
    <LoadingLayoutWrapper loading={loading}>{children}</LoadingLayoutWrapper>
  );
};
