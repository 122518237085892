import {
  useLockLegalClientBranch as useLockApi,
  useUnlockLegalClientBranch,
} from 'api/requests';
import { LegalClientBranchEntity, LegalClientEntity } from 'api/types/entity';
import { useLockClientIfPossible } from 'modules/client/common/hooks';

export const useLockLegalClientBranch = (
  legalClient: LegalClientEntity | null,
  branch: LegalClientBranchEntity | null
) => {
  const { lockClientIfPossible } = useLockClientIfPossible(
    {
      id: branch?.id,
      versionNumber: branch?.versionNumber,
      onboardingStatus: legalClient?.onboardingStatus,
    },
    useLockApi
  );

  const { unlock } = useUnlockLegalClientBranch();
  const unlockClient = () => unlock(branch?.id!);

  return { lockClientIfPossible, unlockClient };
};
