import { useMemo } from 'react';

import { FileEntity } from 'api/types/entity';
import { LegalClientInnerItemEntity } from 'api/types/entity/legal/legalClientInnerItemEntity';
import { useTranslation } from 'libs/i18n';
import { Typography } from 'libs/ui';
import { DocumentsTable, Section } from 'modules/client/common/components';

import { BeneficiaryItem, BeneficiariesTable } from './BeneficiariesTable';

interface Props {
  beneficiary: {
    unidentifiableBeneficiaryLetterFiles?: FileEntity[];
    beneficiaries: LegalClientInnerItemEntity[];
  };
  onClickBeneficiary: (id: string) => void;
}

export const CompanyBeneficiariesSection = ({
  beneficiary,
  onClickBeneficiary,
}: Props) => {
  const { t } = useTranslation();

  const items: BeneficiaryItem[] = useMemo(() => {
    const result = beneficiary.beneficiaries.map((h) => ({
      id: h.id,
      name: h.name,
      location: h.location || '',
      city: h.registrationCity || '',
    }));

    return result;
  }, [beneficiary.beneficiaries]);

  return (
    <Section title={t('client.legal.companyBeneficiaries.title')}>
      <BeneficiariesTable
        items={items}
        onCellClick={(i) => onClickBeneficiary(i.id)}
      />
      {!beneficiary.beneficiaries.length &&
        !!beneficiary.unidentifiableBeneficiaryLetterFiles?.length && (
          <>
            <Typography variant="subtitle">
              {t('client.legal.companyBeneficiaries.documentTitle')}
            </Typography>

            <DocumentsTable
              files={beneficiary.unidentifiableBeneficiaryLetterFiles}
            />
          </>
        )}
    </Section>
  );
};
