import { useTranslation } from 'libs/i18n';
import { Section } from 'modules/client/common/components';
import { DocumentsTableFeature } from 'modules/client/common/feature';
import {
  Field,
  useFormContext,
} from 'modules/client/common/forms/positionInCompanyForm';

export const LegalClientHeadPositionInCompanySection = () => {
  const { t } = useTranslation();
  const { readOnly } = useFormContext();

  return (
    <Section title={t('client.legal.legalClientHead.positionInCompany.title')}>
      <Field.Text
        fullWidth
        label={t(
          'client.legal.legalClientHead.positionInCompany.position.label'
        )}
        name="positionInCompany.position"
        placeholder={t(
          'client.legal.legalClientHead.positionInCompany.position.placeholder'
        )}
      />
      <Field.DatePicker
        fullWidth
        disableFuture={false}
        label={t(
          'client.legal.legalClientHead.positionInCompany.dateOfExpiration.label'
        )}
        name="positionInCompany.dateOfExpiration"
        placeholder={t(
          'client.legal.legalClientHead.positionInCompany.dateOfExpiration.placeholder'
        )}
      />
      <DocumentsTableFeature
        name="positionInCompany.files"
        readOnly={readOnly}
      />
    </Section>
  );
};
