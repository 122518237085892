import { useMemo } from 'react';

import { LegalClientInnerItemEntity } from 'api/types/entity/legal/legalClientInnerItemEntity';
import { useTranslation } from 'libs/i18n';
import { Section } from 'modules/client/common/components';
import { getToday } from 'modules/client/common/helpers';

import { Field } from '../../../forms/companyConstituentDocs';

import { HeadItem, HeadType, HeadsTable } from './HeadsTable';

interface Props {
  heads: LegalClientInnerItemEntity[];
  legalHeads: LegalClientInnerItemEntity[];
  onClickHead: (id: string) => void;
  onClickLegalHead: (id: string) => void;
}

export const CompanyHeadsSection = ({
  heads,
  legalHeads,
  onClickHead,
  onClickLegalHead,
}: Props) => {
  const { t } = useTranslation();

  const items: HeadItem[] = useMemo(() => {
    const result = heads
      .map((h) => ({
        id: h.id,
        name: h.name,
        city: h.registrationCity || '',
        type: HeadType.person,
        createdAt: h.createdAt,
      }))
      .concat(
        legalHeads.map((h) => ({
          id: h.id,
          name: h.name,
          city: h.registrationCity || '',
          type: HeadType.legal,
          createdAt: h.createdAt,
        }))
      );

    result.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

    return result;
  }, [heads, legalHeads]);

  const onCellClick = (item: HeadItem) => {
    if (item.type === HeadType.person) {
      onClickHead(item.id);
    } else {
      onClickLegalHead(item.id);
    }
  };

  return (
    <Section title={t('client.legal.companyHeads.title')}>
      <Field.DatePicker
        label={t('client.legal.companyConstituentDocs.dateOfLastChange.label')}
        maxDate={getToday()}
        name="constituentDocs.dateOfLastChange"
        placeholder={t(
          'client.legal.companyConstituentDocs.dateOfLastChange.placeholder'
        )}
      />
      <HeadsTable items={items} onCellClick={onCellClick} />
    </Section>
  );
};
