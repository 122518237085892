import { AddressesParam, PlaceOfBirthParam } from 'api/types/params';

import { PlaceOfBirthFormType } from '../../forms/placeOfBirthForm';
import { RegistrationAddressFormType } from '../../forms/registrationAddressForm';
import { ResidentialAddressFormType } from '../../forms/residentialAddressForm';

export const parseAddressParam = (
  values: RegistrationAddressFormType &
    ResidentialAddressFormType &
    Partial<PlaceOfBirthFormType>
): {
  registrationAddress: AddressesParam;
  residentialAddress: AddressesParam;
  placeOfBirth: PlaceOfBirthParam;
} => {
  const result = {
    registrationAddress: {
      country: values.registrationAddress.country?.id!,
      region: values.registrationAddress.region,
      apartment: values.registrationAddress.apartment,
      building: values.registrationAddress.building,
      district: values.registrationAddress.district,
      house: values.registrationAddress.house,
      locality: values.registrationAddress.locality,
      postalCode: values.registrationAddress.postalCode,
      street: values.registrationAddress.street,
    },

    residentialAddress: {
      country: values.residentialAddress.country?.id!,
      region: values.residentialAddress.region!,
      apartment: values.residentialAddress.apartment!,
      building: values.residentialAddress.building,
      district: values.residentialAddress.district,
      house: values.residentialAddress.house!,
      locality: values.residentialAddress.locality!,
      postalCode: values.residentialAddress.postalCode!,
      street: values.residentialAddress.street!,
    },

    placeOfBirth: {
      country: values.placeOfBirth?.country?.id!,
      locality: values.placeOfBirth?.locality ?? '',
    },
  };

  return result;
};
