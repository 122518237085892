import { FC } from 'react';

import { CountryEntity, LegalClientInnerItemEntity } from 'api/types/entity';
import { Stack } from 'libs/ui';
import {
  LegalClientHeadInfoSection,
  CompanyNameSection,
  CompanyRegistrationAddressSection,
  CompanyResidentialAddressSection,
  CompanyStateRegistrationSection,
  ActivitySection,
  AdditionalDocumentsSection,
} from 'modules/client/common/components/sections';

import {
  CompanyFirstHeadsSection,
  CompanyTaxResidencySection,
} from '../sections';

export interface LegalClientLegalHeadSectionsProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  firstHeads: LegalClientInnerItemEntity[];
  hideActivitySection?: boolean;
  onClickHead: (id: string) => void;
}

export const LegalClientLegalHeadSections: FC<
  LegalClientLegalHeadSectionsProps
> = ({
  countries,
  countriesLoading,
  firstHeads,
  hideActivitySection,
  onClickHead,
}) => (
  <Stack spacing={72}>
    {!hideActivitySection && <ActivitySection isBaseForm />}
    <LegalClientHeadInfoSection showDocuments />

    <CompanyNameSection />

    <CompanyTaxResidencySection
      countries={countries}
      countriesLoading={countriesLoading}
    />

    <CompanyRegistrationAddressSection
      countries={countries}
      countriesLoading={countriesLoading}
    />

    <CompanyResidentialAddressSection
      countries={countries}
      countriesLoading={countriesLoading}
    />

    <CompanyStateRegistrationSection />

    <CompanyFirstHeadsSection
      firstHeads={firstHeads}
      onClickHead={onClickHead}
    />
    <AdditionalDocumentsSection />
  </Stack>
);
