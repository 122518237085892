import { Box } from 'libs/ui';

import { TwoFactorForm, TwoFactorFormProps } from '../components/TwoFactorForm';

import { LayoutStyled } from './styles';

interface Props extends TwoFactorFormProps {}

export const TwoFactorConnectionView = ({ ...formProps }: Props) => (
  <LayoutStyled>
    <Box className="twoFactorConnection-content">
      <TwoFactorForm {...formProps} />
    </Box>
  </LayoutStyled>
);
