import { Layout } from 'components';
import { CSSObject, styled } from 'libs/ui/styled';

export const ContainerStyled = styled(Layout)(() => ({
  '.layout-content': {
    marginTop: 0,
  },

  '.login-content': {
    display: 'flex',
    flex: 1,
    width: 504,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  } as CSSObject,
}));
