import { CountryEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import {
  Field,
  TaxResidencyFormTaxValue,
} from 'modules/client/common/forms/taxResidencyForm';

interface Props {
  index: number;
  value: TaxResidencyFormTaxValue;
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const Fields = ({
  index,
  value,
  countriesLoading,
  countries,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Field.Autocomplete
        label={t('client.client.taxResidency.country.label')}
        loading={countriesLoading}
        name={`taxResidences.${index}.country`}
        optionLabelKey="name"
        options={countries}
        optionValueKey="id"
        placeholder={t('client.client.taxResidency.country.placeholder')}
      />

      {value.taxNumberIsMissing ? (
        <Field.Text
          key="taxNumberIsMissing"
          label={t('client.client.taxResidency.reasonForAbsence.label')}
          name={`taxResidences.${index}.reasonForAbsence`}
          placeholder={t(
            'client.client.taxResidency.reasonForAbsence.placeholder'
          )}
        />
      ) : (
        <Field.Text
          key="taxNumber"
          label={t('client.client.taxResidency.taxNumber.label')}
          name={`taxResidences.${index}.taxNumber`}
          placeholder={t('client.client.taxResidency.taxNumber.placeholder')}
        />
      )}
    </>
  );
};
