import { string, object, ObjectSchema, TypedForm } from 'libs/form';

import { AddressFormType } from './registrationAddressForm';

export interface ResidentialAddressFormType {
  residentialAddress: AddressFormType;
}

export const residentialAddressSchema: ObjectSchema<ResidentialAddressFormType> =
  object({
    residentialAddress: object({
      country: object({
        id: string().required(),
        name: string().required(),
      })
        .required()
        .default(null),
      locality: string().required().max(200),
      region: string().required().max(200),
      area: string().max(200),
      street: string().required().max(200),
      house: string().required().max(200),
      building: string().max(200),
      apartment: string().max(200),
      postalCode: string().required().max(200),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<ResidentialAddressFormType>();
