import { styled } from 'libs/ui/styled';
import { Colors } from 'libs/ui/theme';

export interface LayoutStyledProps {
  variant?: 'filled' | 'outlined';
}

export const LayoutStyled = styled('div')<LayoutStyledProps>(
  ({ theme, variant = 'filled' }) => ({
    ...(variant === 'filled' && {
      backgroundColor:
        theme.palette.mode === 'light' ? Colors.gray50 : Colors.gray150,
    }),

    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& .layout-content': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      marginTop: 48,
    },

    '& .layout-footer': {
      height: 72,
    },
  })
);
