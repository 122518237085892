import { LegalClientFirstHeadEntity } from 'api/types/entity';
import { formatToISODate } from 'helpers';
import { TFunc } from 'libs/i18n';
import {
  parseAddressValue,
  parseTaxResidencesValue,
  parsePepConnectionInitialValue,
  parseIdentityDocumentInitialValue,
} from 'modules/client/common/helpers';

import { LegalClientFirstHeadFormType } from '../../forms/legalClientFirstHeadForm';
import { PositionInCompanyFormType } from '../../forms/positionInCompany';

import { parseDocumentsFiles } from './parseDocumentsFiles';

export const getLegalClientFirstHeadInitialValue = (
  client: LegalClientFirstHeadEntity,
  t: TFunc
): LegalClientFirstHeadFormType => ({
  contacts: {
    email: client.email,
  },

  personalData: {
    firstName: client.firstName,
    lastName: client.lastName,
    middleName: client.middleName,
    dateOfBirth: client.dateOfBirth,
  },

  citizenships: client.citizenships ?? [null],

  taxResidences: parseTaxResidencesValue(client.taxResidences),

  registrationAddress: parseAddressValue(client.registrationAddress),
  residentialAddress: parseAddressValue(client.residentialAddress),

  pepConnection: parsePepConnectionInitialValue(client.pepConnection, t),

  positionInCompany: client.positionInCompany
    ? {
        dateOfExpiration:
          client.positionInCompany.authorityConfirmationDocument
            .dateOfExpiration,
        files: client.positionInCompany.authorityConfirmationDocument.files,
        position: client.positionInCompany.position,
      }
    : ({} as PositionInCompanyFormType['positionInCompany']),

  activity: {
    id: client.id,
    createdAt: formatToISODate(client.createdAt),
  },

  identityDocument: parseIdentityDocumentInitialValue(
    t,
    client.identityDocument
  ),
  additionalDocumentsFiles: client.additionalInformationFiles,
  documentsFiles: parseDocumentsFiles(client),
});
