import { FC, PropsWithChildren } from 'react';

import { Box, Stack } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';

import { WrapperStyled } from '../Wrapper.styles';

import { HeaderStyled } from './Header.styles';
import { NavigationMenu } from './NavigationMenu';
import { User } from './User';

export interface HeaderProps {
  className?: string;
  hideNavigationMenu?: boolean;
}

export const Header: FC<HeaderProps & PropsWithChildren> = ({
  className,
  hideNavigationMenu,
}) => (
  <HeaderStyled className={className}>
    <WrapperStyled className="header-wrapper">
      <Stack alignItems="center" direction="row" spacing={48}>
        <Box className="header-logo">
          <Icon.Logo />
        </Box>
        {!hideNavigationMenu && <NavigationMenu />}
      </Stack>
      <Box className="header-right">
        <User />
      </Box>
    </WrapperStyled>
  </HeaderStyled>
);
