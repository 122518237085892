import {
  CivilLawSubjectType,
  LegalClientRepresentativeEntity,
} from 'api/types/entity';
import { UpdateLegalClientRepresentativeParams } from 'api/types/params';
import {
  parseAddressParam,
  parseCompanyAddressParam,
  parseCompanyTaxResidencyParam,
  parseStateRegistrationParam,
  parseTaxResidencyParam,
  parsePepConnectionParam,
  parseCitizenshipsParam,
  parseBiographyParam,
  parseAdditionalInformationFileIdsParam,
  parseConstitutionalDocumentParam,
  parseAdditionalDocumentFileIdsParam,
} from 'modules/client/common/helpers';
import { parseIdentityDocumentParam } from 'modules/client/common/helpers/parseParams/parseIdentityDocumentParam';

import { LegalRepresentativeFormType } from '../../forms/legalRepresentativeForm';

export const getLegalRepresentativeSubmitData = (
  versionNumber: number,
  values: LegalRepresentativeFormType,
  client?: LegalClientRepresentativeEntity | null
): UpdateLegalClientRepresentativeParams => ({
  versionNumber,
  firstName: values.personalData.firstName,
  lastName: values.personalData.lastName,
  middleName: values.personalData.middleName ?? null,
  dateOfBirth: values.personalData.dateOfBirth,
  biography: parseBiographyParam(values),
  citizenships: parseCitizenshipsParam(values),

  ...parseAddressParam(values),

  taxResidences: parseTaxResidencyParam(values),

  pepConnection: values.pepConnection.relation?.value!,
  connectedPepInfo: parsePepConnectionParam(values),

  company:
    values.roleInCompany?.civilLawSubject?.value ===
    CivilLawSubjectType.JuridicalPerson
      ? {
          ...parseCompanyAddressParam({
            companyRegistrationAddress: values.companyRegistrationAddress!,
            companyResidentialAddress: values.companyResidentialAddress!,
          }),
          fullName: values.companyName!.fullName,
          shortName: values.companyName!.shortName,

          taxResidences: parseCompanyTaxResidencyParam({
            companyTaxResidences: values.companyTaxResidences!,
          }),
          stateRegistration: parseStateRegistrationParam({
            companyStateRegistration: values.companyStateRegistration!,
          }),
          constitutionalDocument: parseConstitutionalDocumentParam(
            client?.company?.constitutionalDocument
          ),
        }
      : undefined,

  roleInCompany: {
    authorityConfirmationDocument: {
      dateOfExpiration: values.roleInCompany.dateOfExpiration,
      dateOfIssue: values.roleInCompany.dateOfIssue,
      fileIds: values.roleInCompany.files.map(({ id }) => id!),
      number: values.roleInCompany.number,
    },
  },
  additionalInformationFileIds: parseAdditionalInformationFileIdsParam(
    values.additionalDocumentsFiles
  ),

  additionalDocumentFileIds: parseAdditionalDocumentFileIdsParam(
    values.documentsFiles
  ),

  identityDocument: parseIdentityDocumentParam(values),
});
