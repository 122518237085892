import { ErrorLayout, Layout, LoadingLayout } from 'components';

import { useRepresentativeClient } from '../hooks';
import { RepresentativeClientView } from '../views';

export const RepresentativeClientContainer = () => {
  const {
    representativeClient,
    loading,
    countries,
    countriesLoading,
    error,
    initialValues,
    submitLoading,
    breadcrumbsTitles,
    lockClientIfPossible,
    unlockClient,
    onSubmit,
    onChangeClientType,
    onShowVersionHistory,
  } = useRepresentativeClient();

  if (error || (!representativeClient && !loading)) {
    return (
      <Layout>
        <ErrorLayout />
      </Layout>
    );
  }

  if (loading || !representativeClient) {
    return (
      <Layout>
        <LoadingLayout />
      </Layout>
    );
  }

  return (
    <RepresentativeClientView
      breadcrumbsTitles={breadcrumbsTitles}
      countries={countries}
      countriesLoading={countriesLoading}
      initialValues={initialValues}
      kycData={representativeClient.kyc}
      lockClientIfPossible={lockClientIfPossible}
      submitLoading={submitLoading}
      unlockClient={unlockClient}
      onChangeClientType={onChangeClientType}
      onShowVersionHistory={onShowVersionHistory}
      onSubmit={onSubmit}
    />
  );
};
