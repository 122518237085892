import { useTranslation } from 'libs/i18n';
import { Section } from 'modules/client/common/components';
import { DocumentsTableFeature } from 'modules/client/common/feature';
import {
  Field,
  useFormContext,
  nameAuthorityMaxLength,
  numberMaxLength,
  registrationDocumentNameLength,
} from 'modules/client/common/forms/companyStateRegistrationForm';
import { getToday } from 'modules/client/common/helpers';

interface Props {
  hideDocuments?: boolean;
}

export const CompanyStateRegistrationSection = ({ hideDocuments }: Props) => {
  const { t } = useTranslation();
  const { readOnly } = useFormContext();
  return (
    <Section title={t('client.legal.companyStateRegistration.title')}>
      <Field.Text
        label={t(
          'client.legal.companyStateRegistration.registrationDocumentName.label'
        )}
        maxLength={registrationDocumentNameLength}
        name="companyStateRegistration.registrationDocumentName"
        placeholder={t(
          'client.legal.companyStateRegistration.registrationDocumentName.placeholder'
        )}
      />
      <Field.DatePicker
        label={t('client.legal.companyStateRegistration.date.label')}
        maxDate={getToday()}
        name="companyStateRegistration.date"
        placeholder={t(
          'client.legal.companyStateRegistration.date.placeholder'
        )}
      />

      <Field.Text
        label={t('client.legal.companyStateRegistration.number.label')}
        maxLength={numberMaxLength}
        name="companyStateRegistration.number"
        placeholder={t(
          'client.legal.companyStateRegistration.number.placeholder'
        )}
      />

      <Field.DatePicker
        disableFuture={false}
        label={t('client.legal.companyStateRegistration.dateOfIssue.label')}
        maxDate={getToday()}
        name="companyStateRegistration.dateOfIssue"
        placeholder={t(
          'client.legal.companyStateRegistration.dateOfIssue.placeholder'
        )}
      />

      <Field.Text
        label={t('client.legal.companyStateRegistration.nameAuthority.label')}
        maxLength={nameAuthorityMaxLength}
        name="companyStateRegistration.nameAuthority"
        placeholder={t(
          'client.legal.companyStateRegistration.nameAuthority.placeholder'
        )}
      />

      {!hideDocuments && (
        <DocumentsTableFeature
          name="companyStateRegistration.files"
          readOnly={readOnly}
        />
      )}
    </Section>
  );
};
