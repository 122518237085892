import {
  useLockLegalClientLegalHead as useLockApi,
  useUnlockLegalClientLegalHead,
} from 'api/requests';
import {
  LegalClientEntity,
  LegalClientLegalHeadEntity,
} from 'api/types/entity';
import { useLockClientIfPossible } from 'modules/client/common/hooks';

export const useLockLegalClientLegalHead = (
  legalClient: LegalClientEntity | null,
  legalHead: LegalClientLegalHeadEntity | null
) => {
  const { lockClientIfPossible } = useLockClientIfPossible(
    {
      id: legalHead?.id,
      versionNumber: legalHead?.versionNumber,
      onboardingStatus: legalClient?.onboardingStatus,
    },
    useLockApi
  );

  const { unlock } = useUnlockLegalClientLegalHead();
  const unlockClient = () => unlock(legalHead?.id!);

  return { lockClientIfPossible, unlockClient };
};
