import { ErrorLayout, Layout, LoadingLayout } from 'components';
import { useScrollToTop } from 'hooks/useScrollToTop';

import { useLegalClientBranch } from '../hooks';
import { LegalClientBranchView } from '../views/LegalClientBranchView';

export const LegalClientBranchContainer = () => {
  const {
    loading,
    legalClientBranch,
    countries,
    countriesLoading,
    error,
    initialValues,
    submitLoading,
    breadcrumbsTitles,
    lockClientIfPossible,
    unlockClient,
    onSubmit,
  } = useLegalClientBranch();

  useScrollToTop([]);

  if (error || (!legalClientBranch && !loading)) {
    return (
      <Layout>
        <ErrorLayout />
      </Layout>
    );
  }

  if (loading || !legalClientBranch) {
    return (
      <Layout>
        <LoadingLayout />
      </Layout>
    );
  }

  return (
    <LegalClientBranchView
      breadcrumbsTitles={breadcrumbsTitles}
      countries={countries}
      countriesLoading={countriesLoading}
      initialValues={initialValues}
      lockClientIfPossible={lockClientIfPossible}
      submitLoading={submitLoading}
      unlockClient={unlockClient}
      onSubmit={onSubmit}
    />
  );
};
