import { FC } from 'react';

import { CountryEntity } from 'api/types/entity';
import { useFormErrorFocus } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import { FormContentWrapper } from 'modules/client/common/components';
import {
  ContactsSection,
  CompanyNameSection,
  CompanyRegistrationAddressSection,
  CompanyResidentialAddressSection,
  CompanyStateRegistrationSection,
  AdditionalDocumentsSection,
} from 'modules/client/common/components/sections';
import {
  LockAndUnlockReadOnlyProps,
  useLockAndUnlockReadOnly,
} from 'modules/client/common/hooks';
import {
  CompanyLicenseSection,
  CompanyTaxResidencySection,
} from 'modules/client/legal/components/sections';

import { useFormContext } from '../../../forms/legalClientBranchForm';

export interface FormContentProps extends LockAndUnlockReadOnlyProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  submitLoading?: boolean;
}

export const FormContent: FC<FormContentProps> = ({
  countries,
  countriesLoading,
  submitLoading,
  lockClientIfPossible,
  unlockClient,
}) => {
  const { t } = useTranslation();

  const { resetForm, values, dirty, setReadOnly, readOnly } = useFormContext();

  const { fullName } = values.companyName;

  const { onDisableReadOnly, onEnableReadOnly } = useLockAndUnlockReadOnly(
    setReadOnly,
    { unlockClient, lockClientIfPossible }
  );

  useFormErrorFocus();

  return (
    <FormContentWrapper
      content={
        <Stack spacing={72}>
          <CompanyNameSection
            hideShortName
            title={t('client.legal.legalClientBranch.branchName.title')}
          />

          <CompanyTaxResidencySection
            countries={countries}
            countriesLoading={countriesLoading}
          />

          <CompanyRegistrationAddressSection
            countries={countries}
            countriesLoading={countriesLoading}
          />

          <CompanyResidentialAddressSection
            countries={countries}
            countriesLoading={countriesLoading}
          />

          <CompanyStateRegistrationSection hideDocuments />

          <CompanyLicenseSection hideDocuments />

          <ContactsSection
            readOnly={readOnly}
            title={t('client.legal.legalClientBranch.contacts.title')}
          />
          <AdditionalDocumentsSection />
        </Stack>
      }
      dirty={dirty}
      fullName={fullName}
      resetForm={resetForm}
      shownTabBar={false}
      submitLoading={submitLoading}
      onEdit={onDisableReadOnly}
      onEnableReadOnly={onEnableReadOnly}
    />
  );
};
