import {
  useLegalClientHead,
  useLegalClient,
  useLegalClientHeadSnapshot,
} from 'api/requests';
import { LegalClientVersionEntity } from 'api/types/entity';
import { NavigationBreadcrumbsTitles } from 'components';
import { parseFullName } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { ROUTES, useNavigate, usePageTitle, RoutesId } from 'libs/navigation';

import { getLegalClientHeadInitialValue } from '../helpers';

import { useLegalClientHeadVersions } from './useLegalClientHeadVersions';

type ContainerParams = {
  legalClientId: string;
  headId: string;
  versionId?: string;
};

export const useLegalClientHeadVersion = () => {
  const { t } = useTranslation();

  const { params, navigate } = useNavigate<ContainerParams>();

  const { loading: loadingHeadClient, legalClientHead } = useLegalClientHead(
    params.headId!
  );

  const { legalClient } = useLegalClient(params.legalClientId!);

  const { loading: versionsLoading, versions } = useLegalClientHeadVersions(
    params.legalClientId!,
    params.headId!,
    params.versionId
  );

  const {
    snapshot,
    loading: snapshotLoading,
    error,
  } = useLegalClientHeadSnapshot(params.headId!, params.versionId!, {
    fetchPolicy: 'no-cache',
  });

  const initialValues = snapshot?.value
    ? getLegalClientHeadInitialValue(snapshot.value, t)
    : undefined;

  const fullName = legalClientHead ? parseFullName(legalClientHead) : '';

  const clientFullName = legalClient?.fullName;

  const breadcrumbsTitles: NavigationBreadcrumbsTitles = {
    [RoutesId.legalClient]: clientFullName,
    [RoutesId.legalClientVersion]: t('client.client.versions.header', {
      name: clientFullName,
    }),
    [RoutesId.legalClientHeadVersion]: t('client.client.versions.header', {
      name: fullName,
    }),
  };

  const loading =
    (!legalClientHead && loadingHeadClient) || (!versions && versionsLoading);

  const onCloseVersionHistory = () => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalClientHead.fullPath,
      {
        legalClientId: params.legalClientId!,
        headId: params.headId!,
      },
      { replace: true }
    );
  };

  const onChangeVersion = (version: LegalClientVersionEntity) => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalClientVersion
        .legalClientHeadVersion.fullPath,
      {
        legalClientId: params.legalClientId!,
        headId: params.headId!,
        versionId: version.id,
      },
      { replace: true }
    );
  };

  const onBack = () => {
    navigate(
      ROUTES.clients.legalClients.legalClient.legalClientVersion.fullPath,
      {
        legalClientId: params.legalClientId!,
        versionId: params.versionId!,
      },
      { replace: true }
    );
  };

  usePageTitle(fullName);

  return {
    versionId: params.versionId!,
    snapshot,
    loading,
    snapshotLoading: !snapshot && snapshotLoading,
    error,
    versions,
    fullName,
    initialValues,
    breadcrumbsTitles,
    onBack,
    onChangeVersion,
    onCloseVersionHistory,
  };
};
