// eslint-disable-next-line no-restricted-imports
import { ChangeEvent, KeyboardEvent, Ref, forwardRef } from 'react';

import { StyledInput } from './Input.styles';
import { InputProps } from './types';

/**
 * Input component.
 */
export const Input = forwardRef(
  (
    {
      onChange,
      onChangeText,
      onSubmit,
      onKeyDown,
      rightElement,
      leftElement,
      inputRef,
      error,
      fullWidth,
      maxLength,
      trim,
      readOnly,
      mode = 'standard',
      ...props
    }: InputProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (maxLength !== undefined && event.target.value.length > maxLength) {
        return;
      }
      onChange?.(event);
      let { value } = event.target;
      if (trim === 'all') {
        value = value?.trim();
      } else if (trim === 'end') {
        value = value?.trimEnd();
      } else if (trim === 'start') {
        value = value?.trimStart();
      }

      onChangeText?.(value, event);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onSubmit?.(e);
      }
      onKeyDown?.(e);
    };

    const labelTitle =
      props.label && typeof props.label === 'string' ? props.label : undefined;

    const title =
      props.value &&
      (typeof props.value === 'string' || typeof props.value === 'number')
        ? props.value.toString()
        : undefined;

    return (
      <StyledInput
        ref={ref}
        error={Boolean(error)}
        fullWidth={fullWidth}
        InputLabelProps={{ shrink: true, focused: false, title: labelTitle }}
        InputProps={{
          endAdornment: rightElement,
          startAdornment: leftElement,
          readOnly,
          className: 'input-root',
        }}
        inputRef={inputRef}
        mode={mode}
        title={title}
        variant="standard"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        {...props}
      />
    );
  }
);
