import { useEffect, useRef } from 'react';

import { lodash } from 'helpers';
import { useFormContext } from 'libs/form/useFormContext';

export const useFormErrorFocus = () => {
  const { isSubmitting, isValidating, errors } = useFormContext();

  const prevFlag = useRef(false);

  useEffect(() => {
    if (isSubmitting) {
      prevFlag.current = true;
    }
    const isChanged = isSubmitting || prevFlag.current;

    if (!lodash.isEmpty(errors) && isChanged && !isValidating) {
      prevFlag.current = false;
      setTimeout(() => {
        const errorElement = document.querySelector(`[aria-invalid="true"]`);

        if (errorElement) {
          if (errorElement.parentElement?.parentElement) {
            errorElement.parentElement.parentElement.scrollIntoView({
              behavior: 'smooth',
            });
          } else {
            errorElement.scrollIntoView({
              behavior: 'smooth',
            });
          }
        }
      }, 100);
    }
  }, [isValidating, errors, isSubmitting]);
};
