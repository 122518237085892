import { useLayoutEffect, useState } from 'react';

import {
  ClientAccountStatusType,
  ClientOnboardingStatusType,
} from 'api/types/entity';
import { ConfirmationDialog, LoadingLayout } from 'components';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';
import { DialogProps, Input, RadioGroup, Stack } from 'libs/ui';

import { shouldShowResultCheckInput } from './shouldShowResultCheckInput';

export interface DialogChangeStatusProps<
  StatusType extends 'onboarding' | 'account',
  Status extends StatusType extends 'onboarding'
    ? ClientOnboardingStatusType
    : ClientAccountStatusType
> extends Pick<DialogProps, 'isOpen' | 'onClose'> {
  statusType: StatusType;
  statuses?: Status[];
  loading?: boolean;
  submitLoading?: boolean;
  fetchStatuses: () => void;
  onChange: (status: Status, result?: string) => Promise<void>;
  currentStatus: Status | null;
}

export const DialogChangeStatus = <
  StatusType extends 'onboarding' | 'account',
  Status extends StatusType extends 'onboarding'
    ? ClientOnboardingStatusType
    : ClientAccountStatusType
>({
  statusType,
  statuses,
  loading,
  submitLoading,
  isOpen,
  fetchStatuses,
  onChange,
  onClose,
  currentStatus,
}: DialogChangeStatusProps<StatusType, Status>) => {
  const { t } = useTranslation();
  const notify = useNotify();
  const [status, setStatus] = useState<Status | null>(null);
  const [resultCheck, setResultCheck] = useState('');
  const [resultCheckError, setResultCheckError] = useState('');
  const [showResultCheckInput, setShowResultCheckInput] = useState(false);

  const resetForm = () => {
    setStatus(null);
    setResultCheck('');
    setResultCheckError('');
  };

  const onConfirm = async () => {
    const resultHasError =
      showResultCheckInput && resultCheck.trim().length === 0;

    if (resultHasError || !status) {
      if (!status) {
        notify.error(Error(t('client.dialogChangeStatus.statusRequiredError')));
        setResultCheckError('');
      }

      if (resultHasError) {
        setResultCheckError(t('validation.required'));
      }

      return;
    }
    await onChange(status, resultCheck);
  };

  const onCloseHandler = () => {
    onClose?.();
  };

  useLayoutEffect(() => {
    if (isOpen) {
      setShowResultCheckInput(shouldShowResultCheckInput(currentStatus));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useLayoutEffect(() => {
    if (isOpen) {
      fetchStatuses();
    } else {
      resetForm();
    }
  }, [fetchStatuses, isOpen]);

  const title = t(`client.dialogChangeStatus.${statusType}.title`);

  const subtitle =
    statusType === 'onboarding' && showResultCheckInput
      ? t('client.dialogChangeStatus.onboarding.subtitleWithResult')
      : t(`client.dialogChangeStatus.${statusType}.subtitle`);

  return (
    <ConfirmationDialog
      headerCentered
      confirmButtonLabel={t('common.continue')}
      isOpen={isOpen}
      loading={submitLoading || loading}
      maxWidth="md"
      subtitle={subtitle}
      title={title}
      onBack={onCloseHandler}
      onClose={onCloseHandler}
      onConfirm={onConfirm}
    >
      {loading || !statuses ? (
        <LoadingLayout />
      ) : (
        <Stack spacing={32}>
          {showResultCheckInput && (
            <Input
              helperTextAbsolute
              error={Boolean(resultCheckError)}
              helperText={resultCheckError}
              label={t('client.dialogChangeStatus.resultCheck.label')}
              placeholder={t(
                'client.dialogChangeStatus.resultCheck.placeholder'
              )}
              value={resultCheck}
              onChangeText={setResultCheck}
            />
          )}

          <RadioGroup
            groups={statuses.map((status) => ({
              label: t(`client.status.${status}`),
              value: status,
            }))}
            onChange={(e, v) => setStatus(v)}
          />
        </Stack>
      )}
    </ConfirmationDialog>
  );
};
