import { Layout, LoadingLayout, ErrorLayout } from 'components';
import { useTranslation } from 'libs/i18n';

interface Props {
  isError: boolean;
}
export const LoginByTokenView = ({ isError }: Props) => {
  const { t } = useTranslation();

  return (
    <Layout>
      {isError ? (
        <ErrorLayout subtitle={t('auth.loginByToken.subtitle')} />
      ) : (
        <LoadingLayout />
      )}
    </Layout>
  );
};
