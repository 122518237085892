import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientLegalHeadSnapshotQuery } from 'api/generated/graphql';
import { useError, parseLegalClientLegalHeadSnapshotEntity } from 'api/helpers';

import { RequestOptions } from '../types';

export const useLegalClientLegalHeadSnapshot = (
  legalHeadId: string,
  versionId: string,
  options: RequestOptions = {}
) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useLegalClientLegalHeadSnapshotQuery({
    variables: { id: legalHeadId, versionId },
    ...options,
  });

  const error = useError(apolloError);

  const snapshot = useMemo(
    () =>
      data?.legalClientLegalHeadSnapshot
        ? parseLegalClientLegalHeadSnapshotEntity(
            data.legalClientLegalHeadSnapshot
          )
        : null,
    [data?.legalClientLegalHeadSnapshot]
  );

  return {
    snapshot,
    loading,
    error,
    refetch,
  };
};
