import {
  useLockLegalClientBeneficiary as useLockApi,
  useUnlockLegalClientBeneficiary,
} from 'api/requests';
import {
  LegalClientBeneficiaryEntity,
  LegalClientEntity,
} from 'api/types/entity';
import { useLockClientIfPossible } from 'modules/client/common/hooks';

export const useLockLegalClientBeneficiary = (
  legalClient: LegalClientEntity | null,
  beneficiary: LegalClientBeneficiaryEntity | null
) => {
  const { lockClientIfPossible } = useLockClientIfPossible(
    {
      id: beneficiary?.id,
      versionNumber: beneficiary?.versionNumber,
      onboardingStatus: legalClient?.onboardingStatus,
    },
    useLockApi
  );

  const { unlock } = useUnlockLegalClientBeneficiary();
  const unlockClient = () => unlock(beneficiary?.id!);

  return { lockClientIfPossible, unlockClient };
};
