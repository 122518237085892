import { FC } from 'react';

import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';

import {
  Form,
  ClientFormType,
  getSchema,
  FormErrors,
} from '../../forms/clientForm';

import { FormContent, FormContentProps } from './FormContent';

export interface ClientFormProps extends FormContentProps {
  initialValues?: ClientFormType;
  initialErrors?: FormErrors;
  onSubmit?: FormSubmit<ClientFormType>;
}

export const ClientForm: FC<ClientFormProps> = ({
  initialValues,
  initialErrors,
  onSubmit,
  ...formProps
}) => {
  const { t } = useTranslation();

  const schema = getSchema(t);

  return (
    <Form
      initialReadOnly
      initialErrors={initialErrors}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit || (() => {})}
    >
      <FormContent {...formProps} />
    </Form>
  );
};
