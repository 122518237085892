// eslint-disable-next-line no-restricted-imports
import {
  ApolloClient as ApolloClientBase,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
// eslint-disable-next-line no-restricted-imports
import { setContext } from '@apollo/client/link/context';
import { refreshToken } from 'api/requests';
import { createUploadLink } from 'apollo-upload-client';

import { getAuthorizationData } from './getAuthorizationData';
import { getRefreshTokenMiddleware } from './getRefreshTokenMiddleware';
import { tokenStorage } from './tokenStorage';

/**
 * Creates and configures an Apollo Client instance for making GraphQL requests.
 *
 * @param {string} url - The GraphQL server's URL.
 * @param {Langs} locale - The user's selected language.
 * @returns {ApolloClient} - The configured Apollo Client instance.
 */
export const createApolloClient = (url: string, locale: string) => {
  // Create an InMemoryCache instance to cache GraphQL query results.
  const cache = new InMemoryCache();

  // Create an Apollo Client instance with the specified configurations.
  const client = new ApolloClientBase({
    cache,

    // Default options for watch queries
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });

  const uploadLink = createUploadLink({ uri: url });

  client.setLink(
    ApolloLink.from([
      getRefreshTokenMiddleware(async () => {
        const token = tokenStorage.getRefreshToken();
        if (!token) return undefined;
        return refreshToken(client, token);
      }),
      // SetContext link for adding headers to GraphQL requests
      setContext((_, { headers }) => ({
        headers: {
          ...headers,
          // Add authorization headers using
          ...getAuthorizationData(),
          'x-device-id': tokenStorage.getDeviceId(),
          'x-lang': locale,
        },
      })),
      uploadLink,
      new HttpLink({
        uri: url,
      }),
    ])
  );

  return client;
};

// Define the ApolloClient type based on the return type of createApolloClient
export type ApolloClient = ReturnType<typeof createApolloClient>;
