// eslint-disable-next-line no-restricted-imports
import {
  UpdateClientRepresentativeByAdminDto_Input,
  MutationInput_ClientRepresentativeController_UpdateById_Input_PepConnectionType as PepConnectionTypeApi,
} from 'api/generated/graphql';
import { RelationType } from 'api/types/entity';
import { UpdateRepresentativeClientParams } from 'api/types/params';
import { formatToISODate, lodash } from 'helpers';

import { parseIdentityDocumentParam } from '../parseParam';

import { parseBiographyParam, parseBaseDebtsParam } from './parseParam';

const relationType: Record<RelationType, PepConnectionTypeApi> = {
  [RelationType.not]: PepConnectionTypeApi.None,
  [RelationType.yesSpouses]: PepConnectionTypeApi.FamilyMember,
  [RelationType.yesIAm]: PepConnectionTypeApi.Self,
};

export const parseUpdateRepresentativeClientParams = (
  params: UpdateRepresentativeClientParams
): UpdateClientRepresentativeByAdminDto_Input => ({
  versionNumber: params.versionNumber,

  reason: 'TODO reason', // TODO: reason

  firstName: params.firstName,
  lastName: params.lastName,
  middleName: params.middleName,
  dateOfBirth: formatToISODate(params.dateOfBirth),
  citizenships: params.citizenships,
  biography: parseBiographyParam(params.biography),
  debts: parseBaseDebtsParam(params.debts),
  bankAccounts: params.bankAccounts,
  placeOfBirth: params.placeOfBirth,
  registrationAddress: params.registrationAddress,
  residentialAddress: params.residentialAddress,
  taxResidences: params.taxResidences,

  connectedPepInfo: params.connectedPepInfo,

  pepConnectionType: relationType[params.pepConnection],

  powerOfAttorney: {
    ...params.powerOfAttorney,
    dateOfExpiration: formatToISODate(params.powerOfAttorney.dateOfExpiration),
    dateOfIssue: formatToISODate(params.powerOfAttorney.dateOfIssue),
    notary: !lodash.isEmpty(params.powerOfAttorney.notary)
      ? {
          fullName: params.powerOfAttorney.notary.fullName || undefined,
          licenseInfo: params.powerOfAttorney.notary.licenseInfo || undefined,
        }
      : undefined,
  },

  additionalInformationFileIds: params.additionalInformationFileIds,
  additionalDocumentFileIds: params.additionalDocumentFileIds,

  identityDocument: parseIdentityDocumentParam(params.identityDocument),
});
