// eslint-disable-next-line no-restricted-imports
import {
  QueryInput_LegalClientController_GetList_OnboardingStatuses_Items as LegalClientOnboardingStatusTypeApi,
  Query_LegalClientController_GetList_AllOf_1_List_Items_OnboardingStatus as LegalClientOnboardingStatusTypeApi2,
  Query_LegalClientController_GetAvailableStatuses_Onboarding_Items as LegalClientOnboardingStatusTypeApi3,
  Query_LegalClientController_GetById_OnboardingStatus as LegalClientOnboardingStatusTypeApi4,
  Query_LegalClientController_GetById_Onboarding_Statuses_Items_Value as LegalClientOnboardingStatusTypeApi5,
  Query_LegalClientController_GetList_AllOf_1_List_Items_Onboarding_Statuses_Items_Value as LegalClientOnboardingStatusTypeApi6,
} from 'api/generated/graphql';
import { ClientOnboardingStatusType } from 'api/types/entity';
import { Maybe } from 'types/maybe';

type Status =
  | LegalClientOnboardingStatusTypeApi
  | LegalClientOnboardingStatusTypeApi2
  | LegalClientOnboardingStatusTypeApi3
  | LegalClientOnboardingStatusTypeApi4
  | LegalClientOnboardingStatusTypeApi5
  | LegalClientOnboardingStatusTypeApi6;

const statusMap: Record<Status, ClientOnboardingStatusType> = {
  [LegalClientOnboardingStatusTypeApi.FillingOut]:
    ClientOnboardingStatusType.FillingOut,
  [LegalClientOnboardingStatusTypeApi.AccountOpened]:
    ClientOnboardingStatusType.AccountOpened,
  [LegalClientOnboardingStatusTypeApi.ComplianceCheck]:
    ClientOnboardingStatusType.ComplianceCheck,
  [LegalClientOnboardingStatusTypeApi.AccountOpeningDenied]:
    ClientOnboardingStatusType.AccountOpeningDenied,
  [LegalClientOnboardingStatusTypeApi.AmlCftCheck]:
    ClientOnboardingStatusType.AmlCftCheck,
  [LegalClientOnboardingStatusTypeApi.AccountOpening]:
    ClientOnboardingStatusType.AccountOpening,
  [LegalClientOnboardingStatusTypeApi.AwaitingAccountOpening]:
    ClientOnboardingStatusType.AwaitingAccountOpening,
  [LegalClientOnboardingStatusTypeApi.AwaitingAmlCftCheck]:
    ClientOnboardingStatusType.AwaitingAmlCftCheck,
  [LegalClientOnboardingStatusTypeApi.AwaitingComplianceCheck]:
    ClientOnboardingStatusType.AwaitingComplianceCheck,
  [LegalClientOnboardingStatusTypeApi.AwaitingValidation]:
    ClientOnboardingStatusType.AwaitingValidation,
  [LegalClientOnboardingStatusTypeApi.BoardReview]:
    ClientOnboardingStatusType.BoardReview,
  [LegalClientOnboardingStatusTypeApi.ValidationCheck]:
    ClientOnboardingStatusType.ValidationCheck,
  [LegalClientOnboardingStatusTypeApi.PendingBoardReview]:
    ClientOnboardingStatusType.PendingBoardReview,
  [LegalClientOnboardingStatusTypeApi.SigningDocuments]:
    ClientOnboardingStatusType.SigningDocuments,
  [LegalClientOnboardingStatusTypeApi.AwaitingPaymentConfirmation]:
    ClientOnboardingStatusType.AwaitingPaymentConfirmation,
};

export const parseLegalClientOnboardingStatus = (
  status: Maybe<Status>
): ClientOnboardingStatusType =>
  status ? statusMap[status] : ClientOnboardingStatusType.FillingOut;
