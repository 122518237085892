import { LegalClientHeadEntity } from 'api/types/entity';
import { formatToISODate, yesOrNotOption } from 'helpers';
import { TFunc } from 'libs/i18n';
import {
  parseAddressValue,
  parseTaxResidencesValue,
  parsePepConnectionInitialValue,
  parseIdentityDocumentInitialValue,
} from 'modules/client/common/helpers';

import { LegalClientHeadFormType } from '../../forms/legalClientHeadForm';
import { LegalClientHeadType } from '../../forms/legalClientHeadInfoForm';
import { PositionInCompanyFormType } from '../../forms/positionInCompanyForm';

import { parseDocumentsFiles } from './parseDocumentsFiles';

export const getLegalClientHeadInitialValue = (
  client: LegalClientHeadEntity,
  t: TFunc
): LegalClientHeadFormType => ({
  contacts: {
    email: client.email,
  },

  personalData: {
    firstName: client.firstName,
    lastName: client.lastName,
    middleName: client.middleName,
    dateOfBirth: client.dateOfBirth,
  },

  citizenships: client.citizenships ?? [null],

  taxResidences: parseTaxResidencesValue(client.taxResidences),

  registrationAddress: parseAddressValue(client.registrationAddress),
  residentialAddress: parseAddressValue(client.residentialAddress),

  pepConnection: parsePepConnectionInitialValue(client.pepConnection, t),

  headInfo: {
    body: client.legalClientBody?.name ?? '',
    firstHead: yesOrNotOption(t, client.isFirstHead ?? false),
    headType: {
      label: t(`client.legal.legalClientHead.headInfo.headType.person`),
      value: LegalClientHeadType.person,
    },
  },

  positionInCompany: client.positionInCompany
    ? {
        dateOfExpiration:
          client.positionInCompany.authorityConfirmationDocument
            .dateOfExpiration,
        files: client.positionInCompany.authorityConfirmationDocument.files,
        position: client.positionInCompany.position,
      }
    : ({} as PositionInCompanyFormType['positionInCompany']),

  activity: {
    id: client.id,
    createdAt: formatToISODate(client.createdAt),
  },

  identityDocument: parseIdentityDocumentInitialValue(
    t,
    client.identityDocument
  ),
  additionalDocumentsFiles: client.additionalInformationFiles,

  documentsFiles: parseDocumentsFiles(client),
});
